.mul-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 140px;
  max-width: 258px;
}
.mul-form-label {
  color: var(--Grey, #373f41);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
}
.mul-form-title {
  color: #aaa;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
  margin-top: -8px;
}
.rangeContainer {
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 0.5rem;
}
