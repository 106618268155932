
.bidding-list {
    width: 100%;
    margin: 0 auto;
    padding: 16px;
    box-sizing: border-box;

.bidding-rule-section {
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
}

.bidding-update-section {
    display: flex;
    margin-top: 10px;
}

    .arrow-icon {
        display: flex;
    }

    .arrow-icon img {
        transition: transform 0.3s ease;
    }

    .rotate-up {
        display: flex;
        transform: rotate(180deg);
    }

    .rotate-down {
        display: flex;
        transform: rotate(0deg);
    }

    .recipients-title .bidding-rule-section-title {
        width: 50px;
        margin-right: 40px;
    }

    .bidding-rule-section-title {
        width: 140px;
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, serif;
        color: #BAB7B7;
        line-height: 12px;
        text-align: left;
        margin-right: 5px;
    }

    .custom-color-cogs{
        stroke: #BAB7B7;

        &:hover{
            stroke: black !important;
        }
    }

    .custom-color-arrow{
        fill: #BAB7B7;

        &:hover{
            fill: black !important;
        }
    }

.bidding-rule {
    background: white;
    border: 0.5px solid #61D5A9;
    margin-bottom: 4px;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
}

    .bidding-rule-section-content {
        flex-grow: 1;
        font-size: 12px;
        font-weight: 400;
        font-family: Inter, serif;
        color: black;
        line-height: 12px;
        text-align: left;
    }

.rule-header {
    display: flex;
    align-items: center;
}

.rule-details {
    display: flex;
}

.rule-details-container {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    font-family: Inter, serif;
}

.details-first-section {
    padding-left: 10px;
    width: 100px;
    margin-right: 10%;
    display: flex;
    justify-content: flex-start;
}

.details-second-section {
}

.details-third-section {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
}

.recipients-container {
    padding-top: 10px;
    padding-left: 50px;
    display: flex;
    flex-direction: row;
}

.rule-title {
    flex-grow: 1;
}

.rule-title h3 {
    margin: 0;
    font-family: Inter, serif;
    font-weight: 600;
    font-size: 12px;
    color: black;
}

.separator {
    margin-left: 10px;
    margin-top: 5px;
    height: 0.25px;
    background-color: #BAB7B7;
    border: none;
    width: 100%;
}

.source-logo {
    padding-left: 10px;
    width: 100px;
    margin-right: 10%;
    display: flex;
    justify-content: flex-start;
}

.source-logo img {
    width: 30px;
    height: 30px;
}

.expand-icon {
    display: flex;
    padding-right: 8px;
    text-align: center;
}

.average-rank-number {
    font-size: 50px;
    font-weight: 400;
    font-family: Inter;
}

.average-rank-text {
// Additional styles if needed
}

.metrics {
    padding-top: 10px;
}

.metrics p {
    margin: 5px 0;
}
}
