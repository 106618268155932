.bid-cell {
  text-decoration: underline;
  text-decoration-color: #61d5a9;
  text-decoration-thickness: 2px;
  text-decoration-style: dashed;
  line-height: 16px;
  cursor: pointer;
}


.total-cell {
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

.clickable-element {
  color: black;
  text-decoration: none;
}

.clickable-element:hover{
  text-decoration: underline;
}