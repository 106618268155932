.rdrCalendarWrapper {
  border-bottom-right-radius: 10px !important;
}

.rdrDayToday .rdrDayNumber span:after {
  bottom: 2px !important;
}

.rdrWeekDay {
  text-transform: uppercase;
}