.username-link {
  font-family: Inter;
  font-size: 12px;
  color: #12794F;
  text-decoration: none;
  font-weight: 500;
  text-align: left;
  display: block;
  border: none;
  background: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
}