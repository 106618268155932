.report-center-page{
  padding: 16px;
  padding-top: 0px;
  
  .MuiPaper-elevation.report-listing-section{
    background: none;
    box-shadow: none;
    margin-top:10px;
      .report-listing-table{
        box-shadow: none!important;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background: none;
        .reportCenterContainerBox{
          background-color: #ffffff;
        }
      .table-header-report{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 8px;
        padding-left: 10px;
        background: #F2F2F2;
        input{
          margin-top: 2px;
        }
      }
      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        padding: 16px;
      }
      .reportCenterContainerBox {
        tr th {
          padding: 4px 8px;
          font-size: 12px;
          font-family: 'Inter';
          font-weight: 600;
          color: #000000;
          span {
            cursor: pointer;
            flex-direction: inherit;
            -webkit-box-align: center;
            align-items: center;
          }
        }
        tr td{
          font-size: 12px;
          padding: 4px 8px;
          font-family: 'Roboto';
          .cogicon{
            zoom:1.9;
              &:hover {
              path {
                stroke: #000000;
              }
            }
          }
          .active .cogicon path {
            stroke: #000000;
          }
        }
        .no-record-avaiable{
          padding: 16px;
        }
        tbody tr:last-child .MuiTableCell-root {
            border-bottom: none;
        }
        table tr .MuiTableRow-hover:hover{
          background-color: #FAFAFA;
        }
        .css-1gdzcsw-MuiButtonBase-root-MuiButton-root {
          padding: 8px 10px;
          font-size: 1rem;
        }

        .css-3su884-MuiPaper-root,
        .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
          box-shadow: none !important;
        }
      }
    }
  }
}

.listing-menu-options-report {
  border: none;
  padding: 1px;

  label {
      padding: 12px;
      font-size: 1rem;
      color: #6d6565;
  }

  .MuiMenuItem-root {
      padding: 10px 20px;
      font-size: 16px;

      &:hover {
          background-color: #F6F6F6;
      }
  }

  .MuiPaper-root {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px #0000001a;
    position: absolute;
    width: 115px;
    z-index: 10;
    ul li {
      align-items: center;
      color: #333;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      padding: 10px 15px;
      transition: background-color .2s ease;
      .icon{
        height: 16px;
        width: 16px;
      }
    }
    .menu-deleted{
      color: #E70000;
    }
  }
} 