.customAccordion-pannel2 {
  > .css-pwcg7p-MuiCollapse-root {
    background-color: #F2F2F2;
  }
}

.report-form-container {
  border-radius: 1rem;
  box-shadow: none;
  color: #000000;
  padding-left: 8px;

  .MuiFormControl-fullWidth .MuiTypography-body2 {
    color: #000000;
    font-family: Inter, Serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    margin: 5px 0px;
    display: block;
    text-transform: uppercase;
  }

  .MuiFormControl-fullWidth {
    .form-select  {
      background-color: white;
      height: 26px;
      font-size: 10px;
      border:none;
      border-radius: 4px;
      .MuiSelect-select{
        padding-left: 10px;
      }
    }

    .form-select svg {
      color: #BAB7B7;
    }
  }
  .custom-form-select{
    width: auto;
    .MuiInputBase-root {
      height:26px;
      font-size: 12px;
    }
  }

  .MuiFormControl-fullWidth.form-input-select> div{
    background-color: white;
    height: 26px;
    border: 1px solid #C4C4C3;
    border-radius: 5px;
    font-size: 10px;
    color: #000000;
  }

  .additional-settings {
    color: #000000;
    border: 1px solid #BAB7B7;
    padding: 4px;
    font-size: 10px;
    font-family: Inter;
    font-weight: 500;
    line-height: 14.52px;
    text-align: center;
    padding-right: 10px;
    svg {
      zoom: 1.5;
      margin: 0px 5px;
    }
    &:hover{
      border: 1px solid #000000;
      svg path {
        stroke: #000000;
      }
    }
  }

  .form-checkbox-group {
    .MuiFormControlLabel-label, 
    .MuiFormControlLabel-root {
      font-size: 12px;
      margin-left: 0px;
    }

    span.MuiCheckbox-root {
      padding: 5px;
      padding-left: 0px;
    }

    .MuiSvgIcon-root {
      height: 12px;
      width: 12px;
    }
  }
  
  .report-checkbox-rows {
    .MuiButtonBase-root{
      padding:8px;
    }
    .MuiSvgIcon-root {
      height: 12px;
      width: 12px;
    }
  }

  .report-form-footer-section {
      margin-top: 0px;
      margin-bottom:10px;
    .button-container {
      display: flex;
      gap: 15px;
      flex-direction: row;
    }
    .report-form-button{
        width: 120px;
        height: 26px;
        border-radius: 20px;
        padding: 6px 8px;
        background-color: rgb(176, 172, 172);
        color: rgb(55, 63, 65);
       &:hover {
        background-color: #12794F;
        color: white;
       }
      span {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        text-align: center;
      }
    }
  }

  button.MuiButton-containedPrimary, .MuiButton-colorPrimary {
    border-radius: 2rem;
  }

  .error-message p {
    color: #E70000;
  }

  .form-input {
    .MuiOutlinedInput-root {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    input {
      border-bottom: 2px solid #80808080;
      border-radius: 0;
      font-size: 12px;
      padding: 5px 0px 5px 10px;
      &::placeholder {
         font-size : 12px
      }
    }
    .MuiInputBase-fullWidth.Mui-focused input {
      border-bottom: 2px solid #61D5A9;
    }
  }

  .tagify {
    --tags-border-color: none !important;
    border-top: none;
    border-left: none;
    border-right: none;
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 6px 0 7px;
    font-size: 12px;
    border-bottom: 2px solid rgba(128, 128, 128, 0.5019607843);

    &.error-tagify{
        border-bottom: 1px solid #d32f2f !important;
    }
  }

  .tagify__input::before{
      font-size: 12px;
  }
  .tagify__tag {
      --tags-border-radius: 12px;
      --tag-bg: #BAB7B7;
      --tag-hover: #61D5A9;
      font-size: 12px;
      margin-right: 10px;
      &>div{
        border-radius:5px
      }
  }

  .tagify--focus {
      box-sizing: border-box;
      border-bottom: 2px solid #61D5A9 !important;
      &.error-tagify{
          border-bottom: 2px solid #d32f2f !important;
      }
  }

  .tagify--focus:hover {
      border-bottom: 2px solid #61D5A9 !important; 
      &.error-tagify{
          border-bottom: 1px solid #d32f2f !important;
      }
  }


  .tagify__input {
      border: none !important;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      font-size: 10px;
      width: auto;
      background: transparent;
      flex: 1;
      min-width: 120px;
      margin-left: 10px;
  }

  .tagify__input:focus {
      outline: none;
  }

  .tagify__wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
  }

  .quill {
      .ql-toolbar {
        background: #F2F2F2;
      }
      .ql-container{
        background-color: white;
        min-height: 120px;
      }
  }
  .report-custom-date {
    .MuiFormControl-root.MuiTextField-root {
      min-width: 0;
      background: white;
      input {
        font-size: 10px;
        padding-block: 6px;
      }
    }
    button.MuiButtonBase-root{
      margin-right: 0.1rem;
    }
    .custom-date-from ,.custom-date-to {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
}

.report-level-choice {
  .hotel-table-container{
    .header-top{
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .header {
      height: 20px;
    }
  }
}