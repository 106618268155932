.pre-formatted-text {
  color: var(--Grey, #373f41);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  margin: 0;
}

.text-container {
  min-height: 70px;
}

.multiplier-container {
  position: relative;
}
.mul-formGrid {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  flex: 2;
}
.mul-common-form-fields {
  border-right: 1px solid grey;
  padding-right: 30px;
  margin-right: 30px;
  flex: 1;
}
.mul-form-container {
  display: flex;
  justify-content: space-between;
}
.mul-save {
  position: absolute;
  right: 18px;
  bottom: 18px;
  // display: flex;
  // justify-content: flex-end;
  //  align-items: flex-end;
  // margin-right: 35px;
  // margin-bottom: 18px;
}

.mul-chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.mul-chips-container {
  flex: 1.25;
  min-height: 140px;
}
.active-mul-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
}
.multiplier-submit {
  display: flex;
  align-items: center;
  // padding-right: 10px;
}
.topBtn {
  padding-inline: 20px !important;
  margin-right: 3px !important;
  text-transform: none !important;
  font-size: 10px !important;
  border-color: #bab7b7 !important;
  border-radius: 0 0 10px 10px !important;
  border-top-width: 0 !important;
  height: 26px !important;
  width: 130px !important;
  color: #587a78 !important;
}
.mul-common-field-container {
  border-right: 1px solid #c4c4c4;
  // padding-right: 30px;
  display: flex;
  flex-direction: column;
  // gap: 1rem;
  flex: 1;
}
.mul-fields-container {
  padding-left: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  // gap: 1rem;
  flex: 2;
}
.mul-title-button-container {
  display: flex;
  justify-content: space-between;
}
