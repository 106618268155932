.pmax-media-library-editor {
  height: 100%;

  * {
    font-family: 'Inter';
  }

  .header-box {
    margin: 15px 0;
    border-bottom: 1px solid #dcdcdc;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .content-pmax-images {
    background-color: white;
    height: 95%;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    margin-bottom: 10px;
  }
}
