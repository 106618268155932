.search-ads-form-keywords {
  * {
    font-family: 'Inter', sans-serif;
  }
  width: 50%;

  .tabs-container {
    border-bottom: 1px solid #dcdcdc;
    padding: 0;
    margin-bottom: 0;
    margin-top: 15px;

    .custom-tabs {
      display: inline-flex;
      margin: 0;
      padding: 0;

      .keyword-tab {
        height: 28px;
        padding: 10px 25px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        text-align: center;
        color: white;
        background-color: #61D5A9;
        border-radius: 12px 12px 0 0;
        transition: all 0.3s ease;
        margin-right: 1px;
      }

      .negative-keyword-tab {
        height: 28px;
        padding: 10px 25px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        text-align: center;
        color: white;
        background-color: #AAAAAA;
        border-radius: 12px 12px 0 0;
        transition: all 0.3s ease;
        margin-right: 1px;
      }
    }

    .add-button {
      border: 1px solid #dcdcdc;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: #888888;
      background-color: transparent;
      transition: background-color 0.3s, color 0.3s;
      margin-right: 15px;

      &:hover {
        background-color: darkgray;
      }

      svg {
        font-size: 14px;
      }
    }
  }

  .keywords-content {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    gap: 8px;

    .MuiOutlinedInput-root{
      font-size: 12px;
      height: 24.25px;
    }

    .keyword-field {
      gap: 15px;
      display: flex;
      padding-top: 10px;
      margin-bottom: 0;
      border-radius: 4px;
      box-sizing: border-box;

      .match-type-select {
        min-width: 200px;
        width: auto;
        margin-right: 8px;
      }

      .keyword-input {
        margin-right: 8px;
        flex: 1;
      }

      .remove-button {
        border: 1px solid #dcdcdc;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        color: #888888;
        background-color: transparent;
        transition: background-color 0.3s, color 0.3s;
        margin-right: 15px;

        &:hover {
          background-color: darkgray;
        }

        svg {
          font-size: 14px;
        }
      }
    }

    .no-border-focus {
      input {
        padding: 4px;
        border-radius: 10px;
      }

      & .MuiOutlinedInput-notchedOutline {
        border-color: lightgray;
      }

      &:hover .MuiOutlinedInput-notchedOutline,
      &.Mui-focused .MuiOutlinedInput-notchedOutline,
      &:focus-visible .MuiOutlinedInput-notchedOutline {
        border-color: lightgray !important;
      }

      .MuiSelect-select {
        color: black;
        background-color: white;
        height: 20px;
        padding: 3px;
        text-align: left;
        vertical-align: center !important;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: black;
        }
      }

      &.Mui-focused .MuiSelect-select {
        color: black !important;
      }
    }
  }
}
