.google-search-ads-form-campaign-settings {
   .MuiInputLabel-root {
    font-size: 12px; /* Assurez-vous que les labels ont la même taille */
  }

   .MuiOutlinedInput-root {
    font-size: 12px; /* Assurez-vous que les inputs ont la même taille */
  }
  * {
    font-family: 'Inter';
  }

  .sectionTitle {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    color: #373F41;
    margin: 15px;
    text-transform: uppercase;
  }

  .sectionTitleVariable {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    color: #373F41;
    margin: 15px;
    text-transform: uppercase;
  }

  .sectionSubtitle {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    color: #373F41;
    text-transform: uppercase;
  }

  .selectActions {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    color: #373F41;
    display: flex;
    align-items: center;
    gap: 16px;

    .MuiTypography-root{
      font-size: 12px;
    }

    .MuiFormControlLabel {
      margin-right: 16px;
    }

    .MuiFormControlLabel-label {
      font-weight: 700;
    }
  }

  .countrySearchField {
    background-color: transparent;
    padding-left: 8px;
    margin-right: 16px;

    .MuiInputBase-root {
      font-size: 0.875rem;
      color: gray;
      height: 32px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .MuiInputBase-root::before {
      border-bottom: 1px solid lightgray;
    }

    .MuiInputBase-root:hover::before {
      border-bottom: 1px solid darkgray;
    }

    .MuiInputBase-root.Mui-focused::before {
      border-bottom: 1px solid darkgray;
    }

    .MuiInputAdornment-root {
      color: gray;
    }

    .MuiInputBase-input::placeholder {
      color: lightgray;
      opacity: 1;
    }
  }

  .regionGroups {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .continentLabel {
    .MuiFormControlLabel-label {
      font-weight: 700 !important;
      font-size: 12px;
    }
  }

  .countryGroup {
    margin-bottom: 16px;
  }

  .monthlyBudgetInputs {
    display: grid;
    gap: 16px;

    .monthField {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .monthLabel {
        min-width: 80px;
        text-align: right;
      }

      .monthInput {
        flex-grow: 1;
      }
    }
  }

  .MuiFormControlLabel-label {
    font-family: 'Inter';
    font-size: 10px !important;
    color: #333;
  }

  .loadMoreButton {
    text-transform: none;
    color: gray;
    font-size: 0.9rem;
    font-weight: normal;

    &:hover {
      background-color: transparent;
    }
  }

  .no-border-focus {
    input {
      font-size: 12px;
      font-weight: 400;
      height: 25%;
      font-family: Inter;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 6px;
    }

    & .MuiOutlinedInput-notchedOutline {
      border-color: lightgray;
    }

    .MuiSelect-select {
      color: black;
      background-color: white;
      text-align: left;
      vertical-align: center !important;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: black;
      }
    }

    &.Mui-focused .MuiSelect-select {
      color: black !important;
    }
  }

  .custom-helper-text {
    color: #BAB7B7;
    font-size: 8px;
    margin: 0;
    padding-left: 5px;
    padding-top: 5px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    background-color: #F2F2F2;
  }
}
