@import '../../styles/variables';

.groups-container {
  box-sizing: border-box;
  padding: 0 0 0 20px;
  display: flex;
  height: 100%;
  gap: 20px; // Adjust this for spacing between components

  // Left Groups List (20%)
  .groups-list-container {
    flex:1;
    min-width: 15%;
    background-color: $white;
    display: flex;
    flex-direction: column;
  }

  // Middle Groups Hotels List (50%)
  .groups-hotels-list {
    flex: 2;
    min-width: 40%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    overflow: hidden; // Prevent overflow
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  // Right Hotels List (30%)
  .hotels-list-container {
    flex: 2;
    min-width: 40%;
    background-color: $white;
    padding: 20px;
    border-left: 1px solid $grey-color;
    display: flex;
    flex-direction: column;
    overflow: hidden; // Prevent horizontal overflow
  }

  @media (max-width: 1024px) {
    flex-direction: column; // Stack components vertically on smaller screens

    .groups-list-container,
    .groups-hotels-list,
    .hotels-list-container {
      flex-grow: 1; // Equal grow factor to ensure all components are equal in height
      flex-basis: 100%; // Each component should take up the full width
      padding: 10px; // Reduce padding for smaller screens
    }
  }

  .groupTableHeaderTop {
    padding: 0 14px 0 14px;
    align-items: center;
    background-color: #F2F2F2;
    min-height: 46px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;

    h3 {
      margin: 0;
      font-size: 12px;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      color: #000; /* Darker font color */
    }
  }

  .activeGroupBtn {
    border-radius: 16px;
    padding: 1px 16px;
    margin-bottom: 4px;
    font-size: 10px;
    color: #000000;
    border-color: #61D5A9;
    background-color: #61D5A9;
  }

  .disabledGroupBtn {
    border-radius: 16px;
    padding: 1px 16px;
    margin-bottom: 4px;
    font-size: 10px;
    color: #BAB7B7;
    border-color: #BAB7B7;
    background-color: #FFFFFF;
  }
}



