.custom-reporting-pagination-container {
  background-color: white;
  .rows-per-page-container {
    display: flex;
    align-items: center;
  }

  .rows-per-page-label {
    color: #bab7b7;
    font-size: 12px;
    font-weight: 400;
  }

  .rows-per-page-select {
    min-width: 40px;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    color: #000000;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .MuiPaginationItem-root {
      font-size: 12px;
    }
  }
}