.custom-menu {
  .MuiPaper-elevation {
    top: 40px !important;
  }

  .MuiButtonBase-root {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .user-email {
    color: #61d5a9;
    font-size: 10px;
  }
}

.mt-40 {
  margin-top: 40px !important;
}

.product-menu {
  .MuiPaper-elevation {
    top: 40px !important;
  }
}

.apps-menu {
  padding-left: 24px;
  padding-top: 27px;
  width: 260px;
  border-radius: 10px;

  .menu-container {
    margin-bottom: 22px;

    .menu-heading {
      color: #000;
      font-family: "Quincy CF";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .split-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .left-container {
        grid-column: 1/2;
      }

      .right-container {
        grid-column: 2/3;
      }
    }

    .menu-items,
    .split-container {
      padding: 14px 0;

      .menu-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        text-decoration: none;

        .menu-icon {
          height: 18px;
          width: 18px;
          margin-right: 10px;
        }

        .menu-text {
          color: #587a78;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}
