.alert-center {

    * {
        box-sizing: border-box;
    }

    .content {
        width: 100%;
        background: #fafafa;
    }

    .side-bar-container {
        height: 100%;
    }

    .table-container {
        padding-left: 16px;
        padding-right: 16px;
        background-color: #f6f6f6;
        padding-top: 10px;

    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .main-content {
        display: flex;
        height: 100%;
    }


    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .panel-header {
        gap: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .title-container {
        display: flex;
        align-items: center;
    }

    .right-section {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 20px;
    }

    .main-title {
        font-family: Quincy CF, serif;
        font-size: 30px;
        font-weight: 400;
        line-height: 30.9px;
        text-align: left;

    }

    .separator {
        margin: 0 10px;
        font-size: 18px;
        line-height: 1;
        color: lightgray;
        align-self: center;
    }

    .custom-color-cogs{
        stroke: #BAB7B7;

        &:hover{
            stroke: black !important;
        }
    }

    .close-panel-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid #bab7b7;
        padding: 5px;
        cursor: pointer;
        margin-right: 10px;
        box-sizing: border-box;

        &:hover {
            border: 1px solid black;

            > :first-child {
                fill: black;
            }
        }
    }

    .close-panel-icon{
        fill: #BAB7B7;

        &:hover{
            fill: black;
        }
    }

    .sub-title {
        font-family: Inter, serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        color: #BAB7B7;
    }

    .new-alert-btn {
        margin-top: 10px;
    }

    .date-picker {
        margin-bottom: 18px;
    }

    .new-alert-btn {
        height: 30px;
    }

    .alert-content {
        flex-grow: 1; /* Le contenu des alertes prend tout l'espace disponible à droite */
        padding: 20px;
        overflow-y: auto; /* Permet le défilement si nécessaire */
    }

    .alert-table {
        width: 100%;
        border-collapse: collapse;
    }

    .alert-table th {
        background-color: #f2f2f2;
        text-align: left;
    }

    .action-icon {
        cursor: pointer;
        position: relative;
    }

    .alert-action-menu {
        position: absolute;
        background-color: white;
        border: 1px solid #ccc;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 10;
        width: 100px;
    }

    .alert-action-menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .alert-action-menu ul li {
        padding: 8px 12px;
        cursor: pointer;
    }

    .alert-action-menu ul li:hover {
        background-color: #f0f0f0;
    }

}