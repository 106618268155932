.bidding-offline-container {
  border-radius: 10px;

  .row {
    display: flex;
    column-gap: 60px;
    margin-bottom: 20px;
  }
  .row-item-container {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(5, 140px);
  }

  .row-item-container label {
    align-items: center;
  }

  .row .row-item-container .MuiFormControlLabel-root span {
    padding-top: 1px;
  }

  .row-label {
    color: black;
    width: 144px;
    word-wrap: break-word;
    /* Smaller Label */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .export-header {
    color: black;
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    font-size: 12px;
    display: flex;
    gap: 4rem;
    padding-block: 8px;
    padding-inline: 19px;
    border-radius: 10px 10px 0px 0px;
  }
  .export-fields-container {
    background-color: #ffffff;
    padding-block: 27px;
    padding-inline: 19px;
    border-radius: 0px 0px 10px 10px;
  }
  .export-button {
    background-color: #12794f;
    border-radius: 12px;
    height: 24px;
  }
  .dateLabel {
    color: black;

    /* Smaller Label */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}