.report-center-page {
  .report-panel-container{
      align-items: center;
      background-color: #FAFAFA;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: -10px;
      height: auto;
      padding: 20px;
      .platform-selection {
        margin-bottom: 0.2rem;
        &.expanded {
            border-right: 1px solid #61D5A9;
            border-left: 1px solid #61D5A9;
            border-radius: 8px;
        }
       
        .arrow-icon {
          display: flex;
          img {
            transition: transform 0.3s ease;
          }
          .rotate-up {
            transform: rotate(180deg);
          }
          .rotate-down {
              transform: rotate(0deg);
          }
        }
    }

    .report-form-accordion .platform-container {
      &.expanded {
          border-right: 1px solid #61D5A9;
          border-left: 1px solid #61D5A9;
          border-bottom: 1px solid #61D5A9;
          border-radius: 8px;
      }
      .arrow-icon {
        display: flex;
        img {
          transition: transform 0.3s ease;
        }
        .rotate-up {
          transform: rotate(180deg);
        }
        .rotate-down {
            transform: rotate(0deg);
        }
      }
    }
    .collapsible-header {
      border: 1px solid #61D5A9;
      border-radius: 8px;
      padding-left: 10px;
      padding-right: 10px;
      background: white;
      height: 42px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;

      &.expanded {
          border-right: none;
          border-left: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
      }
      
    }

    div.MuiInputBase-fullWidth{
      border: none;
    }
    div.MuiInputBase-fullWidth.Mui-disabled{
      background: #BAB7B7;
    }

    .platform-title {
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", serif;
        color: black;
        text-transform: UPPERCASE;
    }
    .panel-header{
      gap: 10px;
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }
    
    h2.report-form-title {
      font-size: 20px;
      text-align: left;
      font-weight: 400;
      font-family: "Quincy CF", serif;
      line-height: 14.52px;
    }
    .close-panel-circle{
      align-items: center;
      border: 1px solid #bab7b7;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      height: 28px;
      justify-content: center;
      margin-right: 10px;
      padding: 5px;
      width: 28px;
      &:hover{
          border: 1px solid #000;
      }
      .close-panel-icon{
        fill: #bab7b7;
        &:hover{
          fill: #000;
        }
      }
    }
    .collapsible-section {
      background: #FAFAFA;
      border-bottom: 1px solid #61d5a9;
      border-radius: 8px;
      padding: 8px;
      .form-container{
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        gap: 10px;
        padding: 16px;
        .report-button {
          background: white;
          padding: 10px 0;
          height: 24px;
          text-align: center;
          text-transform: none;
          background-color: #f3f3f3;
          border: 1px solid #ccc;
          border-radius: 20px;
          color: #555;
          cursor: pointer;
          color: #bab7b7;
          font-size: 10px;
          &:hover {
            border: 1px solid #373f41;
            color:#373f41
          }
        }
        .report-button.selected {
          background-color: #12794f;
          color: #fff;
        }
      }
    }

    .css-1grcve8-MuiTypography-root, .Mui-error,  .subtitle1{
      font-size: 12px;
    }
    .panel-report-title{
      color: #373f41;
      font-family: Inter, Serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      margin-bottom: 13px;
    }

    p.Mui-error{
      margin-left: 6px;
    }
    .form-select p.Mui-error {
      margin-left: 10px;
    }

    &::before {
      display: none;
    }
  }

  .panel-content {
    background: #FAFAFA;
  }
}