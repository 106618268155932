.bidding-offline-container {
  .import-button-label {
    color: var(--Grey, #bab7b7);

    /* 01 Small Label */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
  }
  .import-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .row-item-container-file{
    font-size: 12px;
    font-weight: 400;
    font-family: Inter;
    display: flex;
  }

}


