.hotel-table-container{

  .alert-table-hotels-title{
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .close-panel-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #bab7b7;
    padding: 5px;
    cursor: pointer;
    margin-right: 10px;
    box-sizing: border-box;

    &:hover {
      border: 1px solid black;

      > :first-child {
        fill: black;
      }
    }
  }

}
