.signUpFormSem {
  height: 100%;
  width: 444px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f2f2f2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1300;
  transition: transform 0.3s ease;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;

  &.open {
    transform: translateX(0);
  }

  .header {
    font-weight: 400;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 16px !important;
    gap: 8px;
    color: black;

    .closeButton {
      color: black;
      border: 1px solid black;
      border-radius: 50%;
      padding: 4px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .title {
      font-size: 20px;
      font-family: "Quincy CF", serif;
      font-weight: 400;
      margin-left: 15px;
    }

  }

  .content {
    overflow-y: auto;
    flex-grow: 1;
    padding: 16px;
  }
}
