.top-hotels-component {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 14px;
  min-height: 215px;
  max-height: 215px;

  .top-hotels-container {
    display: flex;
    padding-bottom: 20px;

    &:not(.loading) {
      align-items: baseline;
      justify-content: space-between;
    }

    .top-hotels-dropdown-container {
      display: flex;
      justify-content: center;
    }

    .dropdown-container {
      padding-left: 15px;
    }

    .top-hotels-table {
      width: 100%;
      padding-top: 20px;

      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 0;

        thead tr {
          text-align: left;
          font-weight: bold;
        }

        th {
          padding-left: 12px;
          padding-block: 0;
          font-weight: 600;
          font-family: Inter, sans-serif;
          font-size: 12px;
          border-bottom: 1px solid #dcdcdc;
          text-align: right;
        }

        td {
          padding: 6px 1px;
          color: black;
          border-bottom: 1px solid #dcdcdc;

          &.first-td {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
            text-overflow: ellipsis !important;
            overflow: hidden;
            white-space: nowrap;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          &:not(.first-td) {
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 11.72px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            text-align: right;

            span {
              margin-right: 20px;
            }
          }

          &.color-td {
            max-width: 10px;
            padding-right: 10px;
            border-bottom: none !important;
          }

          &.no-data {
            border-bottom: none !important;
            text-align: left !important;
            font-size: 12px;
            font-family: Inter, sans-serif;
          }
        }

        tr {
          border-bottom: none;
        }

        th, td {
          &.header-tab {
            cursor: pointer;
          }

          &.color-th {
            max-width: 10px;
            padding-right: 10px;
            border-bottom: none !important;
          }
        }

        .hotel-color {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 3px;
          margin-right: 10px;
          vertical-align: middle;
        }

        @media screen and (max-width: 1650px) and (min-width: 1200px) {
          th:last-child,
          td:last-child {
            display: none;
          }

          th:nth-last-child(2),
          td:nth-last-child(2) {
            display: none;
          }
        }
      }
    }

    .top-hotels-chart-column {
      padding-top: 0 !important;
      padding-left: 45px !important;

      @media screen and (max-width: 1650px) and (min-width: 1200px) {
        padding-left: unset !important;
      }

      .top-hotels-chart-container {
        padding-top: 15px;
        width: 100%;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;

        .loader {
          width: 50px;
          height: 50px;
        }

        .custom-tooltip {
          background-color: #fff;
          padding: 5px;
          border: 1px solid #ccc;
          font-family: Inter, sans-serif;
          font-size: 12px;
        }
      }
    }
  }
}