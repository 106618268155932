.kpi-metrics-container {
  .metrics-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    justify-content: space-between;

    .metrics-box {
      background-color: white;
      border-radius: 10px;
      flex: 1 1 calc(16.66% - 14px);
      position: relative;
      max-height: 88px;
      min-height: 88px;
      margin-bottom: 14px;
      margin-right: 14px;

      .metrics-box-content {
        padding: 10px 20px;

        .score-title {
          color: black;
          font-family: Inter, serif;
          font-size: 12px !important;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .score-value {
          color: black;
          font-family: Inter, serif;
          font-size: 26px;
          font-weight: 400;
          line-height: 31.47px !important;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }

        .score-increase {
          color: var(--Dark-Green, #12794f);
        }

        .score-decrease {
          color: var(--Red, #e70000);
        }

        .score-percentage {
          font-family: Inter, serif;
          font-size: 12px !important;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .comparison-label {
          font-size: 8px;
        }
      }

      .metrics-box-bottom-bar {
        border-radius: 0 0 10px 10px;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 5px;
      }
    }
  }

  /* For screens smaller than 1440px */
  @media (max-width: 1440px) {
    .metrics-row {
      .metrics-box {
        flex: 1 1 calc(33.33% - 14px);
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  /* Optional: Further adjustment for smaller screens (e.g., mobile) */
  @media (max-width: 768px) {
    .metrics-row {
      flex-direction: column;
      .metrics-box {
        flex: 1 1 100%;
        margin-right: 0;
      }
    }
  }
}
