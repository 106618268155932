.formGrid {
  display: flex;
  flex: 2;
  margin-top: 9px;
}

.form-title {
  color: #aaa;

  /* Smaller Label */
  font-family: Inter;
  margin-top: 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
}
.form-button-container {
  display: flex;
  align-items: flex-end;
}
.common-field-container {
  border-right: 1px solid #c4c4c4;
  display: flex;
  flex-direction: column;
  //flex: 1;
}
.fields-container {
  padding-left: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  //flex: 3;
  //width: 50%;
}
.form-grid-item {
  margin-right: 30px;
  margin-bottom: 15px;
}

.save {
  align-self: flex-end;
  margin-bottom: 15px;
}

.bidding_multiplier_height {
  margin-top: 10px!important;
}
