.bidding-offline{

  box-sizing: border-box;

  label{
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    width: 135px;
  }

  a{
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
  }

  li{
     list-style: none;
  }

  .details-section{
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
  }

  .details-container{
    width: 100% ;
  }

  .details-header{
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    border-radius: 8px 8px 0 0;
    border: 1px solid #32d69f;
    padding: 0 15px 0;
    height: 42px;
  }



  .panel-container{
    display: flex;
  }

  .bidding-offline-panel-container{
    height: auto;
    width: 100%;
    background-color: #FAFAFA;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .panel-content {
    box-sizing: border-box;
    padding: 15px;
    background: #FAFAFA;
  }

  .panel-detailed-content {
    padding: 15px 15px 15px;
    width: 100%;
    display: flex;
    border: 1px solid #32d69f;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .panel-header {
    gap: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .custom-color-cogs{
    stroke: #BAB7B7;

    &:hover{
      stroke: black !important;
    }
  }

}