@import "../../../styles/variables.scss";

.sidebar {
  height: calc(100vh - 80px);

  .list-menu {
    display: flex;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .menu-icon {
      margin-top: 10px;
      width: 30px;
      height: 30px;
    }

    .list-menu-name {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin: 1px 8px;
      font-family: $inter;
      color: #587A78;
    }
  }

  .activeMenuBG {
    background-color: #F1F1F1;
  }

  .my-products-title,
  .coming-soon {
    color: #373f41;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 20px;
    padding-bottom: 2px;

    .underline {
      border-bottom: 1px solid #BAB7B7;
      display: inline;
      padding-bottom: 2px;
    }

  }

  .coming-soon {
    margin-top: 45px;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  .MuiDrawer-paper {
    overflow-y: auto;

    .MuiList-root {
      height: inherit;
      overflow: auto !important;
    }
  }

  .expanded-container {
    color: $black;

    .title {
      padding-top: 3px;
      padding-bottom: 11px;
      padding-right: 10px;
      font-size: 12px;
      font-weight: 400;
      margin-left: 8px;
    }

    .MuiListItem-root {
      margin: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 10px !important;
    }
  }

  .MuiListItemText-primary {
    font-family: $inter;
    font-size: 12px!important;
    color: $black;
    font-weight: 400 !important;
    padding: 5px 0 5px 0!important;
  }

  .menuItem-child {
    border-left: 1px solid #c4c4c4;
    margin-left: 12px;
    font-size: 10px!important;
    font-weight: 400!important;

    .MuiListItem-root {
      margin: 0 !important;
      padding: 0 0 0 10px !important;

      .MuiListItemText-root {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      .MuiListItemText-primary {
        font-size: 10px !important;
        font-family: $inter;
        color: $black;
        font-weight: 400!important;
      }
    }
  }

  .MuiToolbar-root {
    box-shadow: none !important;
  }

}
