.hotels-and-groups-list {
        display: flex;
        flex-direction: column;

    .hotel-collapsible-section {
        width: 100%;
        padding-top: 5px;

        .paper{
            border: 1px solid #ebebeb;
        }
    }

    .arrow-icon img {
        transition: transform 0.3s ease;
    }

    .rotate-up {
        transform: rotate(180deg);
    }

    .rotate-down {
        transform: rotate(0deg);
    }

    .close-panel-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid #bab7b7;
        padding: 5px;
        cursor: pointer;
        margin-right: 10px;
        box-sizing: border-box;

        &:hover {
            border: 1px solid black;

            > :first-child {
                fill: black;
            }
        }
    }

    .close-panel-icon{
        fill: #BAB7B7;

        &:hover{
            fill: black;
        }
    }

    .hotel-collapsible-header {
        border: 1px solid #61D5A9;
        border-radius: 8px;
        padding: 10px;
        background: white;
        height: 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.expanded {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .hotel-collapsible-content {
        background: white;
        border-bottom: 1px solid #61D5A9;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        border-right: 1px solid #61D5A9;
        border-left: 1px solid #61D5A9;
    }

    .hotel-title {
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", serif;
        line-height: 14.52px;
        color: #373F41;
    }

    .hotel-collapsible-content {
        padding: 10px;
    }

}
