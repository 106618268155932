.subUserMgmt {
  padding-right: 16px;
  padding-left: 16px;

  .searchBoxContainer {
    margin-top: 10px;
    align-items: center;
    background-color: #EBEBEB;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .createUserBtn {
    font-family: 'Inter', serif;
    background-color: #12794F;
    color: white;
    border-radius: 25px;
    padding: 4px 8px;
    margin-right: 2px;
    margin-bottom: 8px;
    margin-top: -10px;
    font-size: 12px;
    width: 125px;
    font-weight: 400;
    text-transform: none;
    display: flex;
    align-items: center;
  }

  .createUserBtn:hover {
    background-color: #276749;
  }

}