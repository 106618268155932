.placeholder {
  color: var(--Grey, #bab7b7);

  /* 01 Small Label */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
