.user-accordion {
  .user-accordion-input-label {
    color: #373F41 !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 12.1px !important;
    margin-bottom: 4px !important;
  }

  .user-accordion-text-field {
    background-color: white;

    .MuiOutlinedInput-root {
      height: 28px;
    }

    .MuiOutlinedInput-input {
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
    }
  }

  .user-accordion-select-dropdown {
    background-color: white;
    border-radius: 6px;
    font-size: 12px !important;
    line-height: 14.52px !important;
  }

  .user-accordion-select-dropdown .MuiSelect-select {
    padding: 4px 8px !important;
    display: flex !important;
    align-items: center;
    font-size: 12px !important;
    line-height: 14.52px;
    font-weight: 400 !important;
  }

  .dialogButton {
    text-transform: capitalize !important;
    padding: 2px 8px !important;
    margin-bottom: 10px !important;
    height: 25px !important;
    width: 85px !important;
    border-radius: 20px !important;
    font-size: 10px !important;

    &.dialogButtonCancel {
      background-color: #bab7b7 !important;
      color: #000000 !important;
    }

    &.dialogButtonApply:hover {
      background-color: #12794f !important;
      color: #ffffff !important;
    }

    &.dialogButtonCancel:hover {
      background-color: #12794f !important;
      color: #ffffff !important;
    }

    &.cancelHovered {
      background-color: #bab6b6 !important;
      color: #000000 !important;
    }
  }
}