.adsCreativeContainer {
  * {
    font-family: 'Inter';
  }

  .header-box {
    margin: 14px 0;
    border-bottom: 1px solid #dcdcdc;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;

    h6{
      font-size: 12px;
      font-family: Inter;
      font-weight: 400;
    }

    .outlined-info-button {
      border: none;
      color: black;
      background-color: transparent;
      border-radius: 50%;
      padding: 0;
      width: 21px;
      height: 21px;

      svg {
        font-size: 21px;
        color: dimgray;
      }
    }

    .add-button {
      border: 1px solid #dcdcdc;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: #888888;
      background-color: transparent;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: darkgray;
      }

      svg {
        font-size: 14px;
      }
    }
  }

  .content-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    .add-button-text {
      border: 1px solid #61D5A9;
      color: white;
      border-radius: 50%;
      padding: 0;
      width: 20px;
      height: 20px;
      background-color: #61D5A9;
      margin-right: 5px;
      margin-left: 15px;

      &:hover {
        background-color: #f5f5f5;
        color: #555555;
      }

      svg {
        font-size: 14px;
      }
    }

    .remove-button {
      border: 1px solid #dcdcdc;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: #888888;
      background-color: transparent;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: darkgray;
      }

      svg {
        font-size: 14px;
      }
    }

    .input-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .phone-input-field {
      border-radius: 6px;
      width: 100%;
      font-family: Inter, sans-serif;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 4px;
      background-color: #fff;

      & input {
        border: none;
        outline: none;
        color: rgba(0, 0, 0, 0.87);
        background-color: #fff;
        font-family: inherit;
        box-sizing: border-box;
      }

      & input:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }

      & .PhoneInputCountry {
        padding-left: 8px;
      }

      & .PhoneInputCountrySelect {
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0;
        outline: none;
        cursor: pointer;
      }
    }
  }

  .content-box-one-line {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;

    .add-button-text {
      border: 1px solid #61D5A9;
      color: white;
      border-radius: 50%;
      padding: 0;
      width: 20px;
      height: 20px;
      background-color: #61D5A9;
      margin-right: 5px;
      margin-left: 15px;

      &:hover {
        background-color: #f5f5f5;
        color: #555555;
      }

      svg {
        font-size: 14px;
      }
    }

    .remove-button {
      border: 1px solid #dcdcdc;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: #888888;
      background-color: transparent;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: darkgray;
      }

      svg {
        font-size: 14px;
      }
    }

    .input-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .phone-input-field {
      border-radius: 6px;
      width: 100%;
      font-family: Inter, sans-serif;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.23);
      padding: 4px;
      background-color: #fff;

      & input {
        border: none;
        outline: none;
        color: rgba(0, 0, 0, 0.87);
        background-color: #fff;
        font-family: inherit;
        box-sizing: border-box;
      }

      & input:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }

      & .PhoneInputCountry {
        padding-left: 8px;
      }

      & .PhoneInputCountrySelect {
        border: none;
        background-color: transparent;
        font-size: 16px;
        padding: 0;
        outline: none;
        cursor: pointer;
      }
    }
  }

  .no-border-focus {

    input {
      font-size: 12px;
      font-weight: 400;
      font-family: Inter;
      padding-left: 10px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 6px;
    }

    & .MuiOutlinedInput-notchedOutline {
      border-color: lightgray;
    }

    &:hover .MuiOutlinedInput-notchedOutline,
    &.Mui-focused .MuiOutlinedInput-notchedOutline,
    &:focus-visible .MuiOutlinedInput-notchedOutline {
      border-color: lightgray !important;
    }

    .MuiSelect-select {
      color: black;
      background-color: white;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: black;
      }
    }

    &.Mui-focused .MuiSelect-select {
      color: black !important;
    }
  }
}
