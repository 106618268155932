.dialogTitle {
  font-size: 10px;
  color: Grey;
}

table.table-value-editor {
  tr {
    border-bottom: 0.25px solid #bab7b7;
    &:nth-last-child(1) {
      border-bottom: none;
    }
    &:first-child {
      border-bottom: 1.5px solid #545454;
    }
    th {
      text-align: start;
      color: #545454;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 10px 0;
    }
    td {
      padding: 10px 0;
      color: #545454;
      font-family: Inter;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
