.bidding-center-page {

    .MuiAutocomplete-listbox {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

    .MuiAutocomplete-option {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

    .MuiList-root span{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

}

.bidding-rule-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;

    label{
        color: black;
        font-family: Inter, Serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 12.1px;
    }


    .bidding-rule-name-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 400;
        font-size: 10px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        gap: 30px;
    }

    .bidding-rule-full-name {
        display: flex;
        flex-direction: column;
        width: 100%;
    }


    .bidding-rule-short-name {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .bidding-rule-name-row {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .bidding-rule-name-container .MuiTextField-root {
        flex-grow: 1;
    }


    .bidding-rule-metric-analysis-container {
        margin-top: 10px;
        color: #373f41;
        font-weight: 400;
        font-size: 10px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: space-between;
    }

    .bidding-frequency-container {
        color: #373f41;
        padding-top: 3px;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .button-container{
        display: flex;
        gap: 15px;
    }

    .metric-analysis {
        color: black;
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .metric-analysis-formcontrol {
        width: 180px;
    }

    .bidding-frequency-checkboxes-container {
        display: flex;
        flex-direction: column;
    }

    .bidding-frequency-checkboxes {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .bidding-rules-triggered-container {
        margin-top: 10px;
        font-weight: 400;
        font-size: 10px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .bidding-rules-triggered-container .bidding-auto-complete {
        display: flex;
    }

    .bidding-rules-not-triggered-container {
        margin-top: 10px;
        font-weight: 400;
        font-size: 10px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .bidding-rules-not-triggered-container .bidding-auto-complete {
        display: flex;
    }

    .bidding-rules-triggered-container-fields {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .value-selector-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .value-selector-container button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border: 0.5px solid #BAB7B7;
        border-radius: 50%;
    }

    .value-selector {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .value-selector-container button {
        height: 18px;
        width: 18px;
    }

    .value-container {
        margin-top: 10px;
        font-weight: 400;
        font-size: 10px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .value-row {
        display: flex;
        flex-direction: column;
    }

    .import-bidding-rule-container {
        margin-top: 10px;
        font-weight: 400;
        font-size: 10px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-top: 3px;
    }

    .email-container {
        margin-top: 10px;
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: column;
    }

    .email-container-fields {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .email-container-fields .tagify__input {
        font-size: 12px;
        min-width: 300px;
        padding-left: 0;
        padding-right: 0;
    }

    .tagify {
        --tags-border-color: none !important;
        border-top: none;
        border-left: none;
        border-right: none;
        display: inline-block;
        position: relative;
        --tag-border-radius: 12px;
        width: 100%;
        padding: 6px 0 7px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
        --tag-pad: 0.2em 0.5em;
        &.error-tagify{
            border-bottom: 1px solid #d32f2f !important;
        }
    }

    .tagify__input::before{
        font-size: 12px;
    }

    .tagify__tag {
        margin: 0 0 0 0;
    }


    .email-checkbox-container {
        display: flex;
        flex-direction: row;
        max-width: 300px;
    }

    .email-checkbox-container span{
        display: inline-flex;
        align-items: flex-start;
    }

    .email-checkbox-label {
        width: 150px;
        padding-top: 5px;
        display: flex;
        align-self: center;
    }

    .days-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .daily-frequency-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .frequency-fields {
        display: flex;
        flex-direction: column;
    }

    .frequency-field {
        gap: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .bidding-auto-complete {
        display: flex;
        flex-direction: column;
        width: 180px;
    }

    .tagify {
        --tags-border-color: none !important;
        border-top: none;
        border-left: none;
        border-right: none;
        --tag-pad: 0.2em 0.5em;
        display: inline-block;
        position: relative;
        width: 100%;
        --tag-border-radius: 12px;
        padding: 6px 0 7px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    }

    .tagify__tag {
        font-size: 12px;
        --tags-border-radius: 12px;
        --tag-bg: #BAB7B7;
        --tag-hover: #61D5A9;
    }

    .tagify:hover {
        border-bottom: 2px solid black !important;
        &.error-tagify{
            border-bottom: 1px solid #d32f2f !important;
        }
    }

    .tagify--focus {
        border-bottom: 2px solid #61D5A9 !important; /* Changement de la couleur de la bordure au focus */
        &.error-tagify{
            border-bottom: 2px solid #d32f2f !important;
        }
    }

    .tagify--focus:hover {
        border-bottom: 2px solid #61D5A9 !important; /* Changement de la couleur de la bordure au focus */
    }

    .MuiInputBase-root {
        font-size: 12px;
    }

    .tagify__input {
        border: none !important;
        font-size: 10px;
        box-shadow: none;
        margin-left: 0;
        border-radius: 0;
        padding: 0;
        width: auto;
        background: transparent;
        flex: 1;
        min-width: 120px;
    }

    .tagify__input:focus {
        outline: none;
    }

    .tagify__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .frequency-field .MuiFormControl-root,
    .frequency-field .MuiAutocomplete-root {
        width: 100%;
    }

    .frequency-field .MuiInputLabel-root {
        font-size: 12px;
    }

    .MuiAutocomplete-root{
        font-size: 12px !important;
    }

    .frequency-field .MuiOutlinedInput-root {
        font-size: 12px;
    }

    .frequency-fields .MuiFormControl-root {
        margin: 0;
        min-width: 180px;
    }


    .bidding-auto-complete .MuiFormControl-root,
    .bidding-auto-complete .MuiAutocomplete-root {
        width: 100%;
    }

    /* Ajustez les marges et paddings pour aligner les éléments */
    .bidding-auto-complete .MuiInputLabel-root {
        font-size: 12px; /* Assurez-vous que les labels ont la même taille */
    }

    .bidding-auto-complete .MuiOutlinedInput-root {
        font-size: 12px; /* Assurez-vous que les inputs ont la même taille */
    }

    /* Si nécessaire, ajustez les marges des FormControl */
    .bidding-auto-complete .MuiFormControl-root {
        margin: 0; /* Supprimez les marges par défaut */
    }

    .query-builder-container .MuiAutocomplete-option {
        font-size: 12px !important;
    }

    .query-builder-container .MuiAutocomplete-listbox .MuiAutocomplete-option {
        font-size: 12px !important;
    }

    .query-builder-result {
        display: block;
    }

    .query-builder button {
        box-sizing: border-box;
        font-size: 12px;
        font-weight: 400;
        font-family: Inter, Serif;
        color: #373f41;
        padding: 10px 15px;
        cursor: pointer;
    }

    .query-builder .MuiButton-containedPrimary {
        box-sizing: border-box;
        border: 2px solid #e0e0e0 !important;
        border-radius: 5px  ;
        width: 40px;
        height: 30px;
    }

    .query-builder .MuiButton-containedInherit{
        border-radius: 5px;
        border: 1px solid #e0e0e0 !important;
        width: 40px;
        height: 30px;
    }

    .rule.group-or-rule{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder {
        margin: 0 !important;
        padding-top: 5px;
        width: 100%;
    }

    .query-builder-container .query-builder .MuiSelect-select {
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder-container .query-builder .MuiInputBase-input {
        font-size: 12px !important;
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder-container .query-builder .MuiButtonGroup-root {
        gap: 10px;
    }

    // Cible tous les éléments MenuItem dans les dropdowns du Query Builder
    .query-builder-container .query-builder .MuiMenuItem-root {
        font-size: 12px !important; // Définissez la taille de la police souhaitée
    }

    // Cible les labels dans le Query Builder
    .query-builder-container .query-builder label {
        font-size: 12px !important;
    }

    .query-builder-container .query-builder .MuiSvgIcon-root {
        color: #bab7b7;
    }

    .query-builder .query-builder .MuiButtonBase-root:hover {
        background: #61D5A9 !important;
        border-color: #61D5A9 !important;
    }

    .query-builder .MuiButton-containedPrimary {
        background-color: #61D5A9 !important;
        color: black !important;
    }

    .query-builder .rule {
        background-color: #FAFAFA;
        border: 1px solid #61D5A9 !important;
        padding: 5px;
    }

    .query-builder .group {
        background: #FAFAFA !important;
        border-color: #FAFAFA !important;
    }


    .query-builder-container {
        margin-top: 10px;
        font-weight: 400;
        font-size: 10px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        background-color: #FAFAFA;
        border-radius: 8px;
    }

    .query-builder .group .group-or-rule {
        background: #FAFAFA;
        border-color: #FAFAFA;
    }

    .query-builder .group--actions {
        padding: 10px 0;
    }

    .group--actions button {
        color: black;
    }

    .query-builder .btn--add-rule,
    .query-builder .btn--add-group {
        background-color: #6c757d;
        border-radius: 4px;
        padding: 6px 12px;
        color: white;
    }

    .query-builder .operator--btn {
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        padding: 6px;
        border-radius: 4px;
    }

    .query-builder select {
        padding: 5px 8px;
        border-radius: 4px;
    }

    /**/
    .query-builder .MuiInputBase-root.MuiInput-root {
        border: 1px solid #C4C4C4;
        background: white;
        height: 30px;
        border-radius: 4px;
        padding: 4px 8px;

        transition: border-color 0.3s;
    }

    .query-builder .MuiInput-underline:before,
    .query-builder .MuiInput-underline:hover:before,
    .query-builder .MuiInput-underline:after {
        border-bottom: none;
    }

    .query-builder .MuiInputBase-root.MuiInput-root:hover {
        border-color: #61D5A9;
    }


    .query-builder .MuiInputBase-root.MuiInput-root.Mui-focused {
        border: 1px solid #61D5A9;
    }

    .query-builder .MuiInputBase-input::placeholder {
        font-size: 12px;
        font-weight: 400;
        font-family: Inter, Serif;
        line-height: 12.1px;
    }



    .query-builder .MuiInputLabel-root {
        background-color: white;
        padding: 0 4px;
        transform: translate(14px, -6px) scale(0.75);
    }

    .rule--body--wrapper{
        display: flex;
        justify-content: center;
    }


}