// Variables for reusable values
$font-inter: 'Inter', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-size-small: 10px;
$font-size-medium: 12px;
$line-height-small: 11.72px;
$line-height-medium: 14.52px;
$border-color: #dcdcdc;
$bg-color-white: #ffffff;

.device-reporting-component {
  background-color: $bg-color-white;
  border-radius: 10px;
  padding: 20px;
  min-height: 190px;
  max-height: 190px;

  .metric-dropdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .device-metrics-container {
    display: flex;

    .metrics-table {
      width: 100%;
      padding-top: 20px;

      table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 0;

        thead {
          tr {
            text-align: left;
            font-weight: bold;

            th {
              padding-left: 12px;
              font: 600 $font-size-medium $font-inter;
            }
          }
        }

        td {
          padding: 7px 1px;
          color: black;

          &.first-td {
            font: 400 $font-size-medium $font-inter;
            line-height: $line-height-medium;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          &:not(.first-td) {
            font: 400 $font-size-medium $font-roboto;
            line-height: $line-height-small;
            text-align: right;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

            span {
              margin-right: 20px;
            }
          }

          .iconDevice {
            margin-right: 5px;
            vertical-align: middle;

            @media (max-width: 1440px) {
              display: none;
            }

            &.desktop {
              margin-left: 2px;
            }
            &.tablet {
              margin-left: 3px;
            }
          }
        }

        tr {
          border-bottom: none;
        }

        th,
        td {
          border-bottom: 1px solid $border-color;

          &.first-td {
            text-align: left;
          }

          &:not(.first-td) {
            text-align: right;
          }

          &.header-tab {
            cursor: pointer;
          }

          &.color-th,
          &.color-td {
            max-width: 10px;
            border-bottom: none !important;
            @media (max-width: 1440px) {
              padding-right: 10px;
            }
          }

          &.no-data {
            border-bottom: none !important;
            text-align: left !important;
            font-size: $font-size-medium;
            font-family: Inter, sans-serif;
          }
        }

        .device-color {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 3px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
    }

    .chart-container {
      width: 100%;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;

      .loader {
        width: 50px;
        height: 50px;
      }

      .text-percentage {
        font: 400 $font-size-small $font-roboto;
        line-height: $line-height-small;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }

      .custom-tooltip {
        background-color: $bg-color-white;
        padding: 5px;
        border: 1px solid #ccc;
        font: $font-size-medium $font-inter;
      }
    }
  }
}