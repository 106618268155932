.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
}

.MuiDialog-container .cancelUploadBtn {
  border-radius: 20px!important;
  padding: 2px 16px!important;
  font-size: 10px!important;
  color: #000000!important;
  border-color: #BAB7B7!important;
  background-color: #FFFFFF!important;
}

.MuiDialog-container .activeUploadBtn {
  border-radius: 20px!important;
  padding: 2px 16px!important;
  font-size: 10px!important;
  color: #000000!important;
  border-color: #61D5A9!important;
  background-color: #61D5A9!important;
}

.MuiDialog-container .inActiveUploadBtn {
  border-radius: 20px!important;
  padding: 2px 16px!important;
  font-size: 10px!important;
  color: #BAB7B7!important;
  border-color: #BAB7B7!important;
  background-color: #FFFFFF!important;
}

.MuiDialog-container .css-ypiqx9-MuiDialogContent-root {
  padding: 1px 22px!important;
}

.MuiDialog-container .css-knqc4i-MuiDialogActions-root {
  padding-right: 24px!important;
  padding-bottom: 15px!important;
}