.select {
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}

.menuContainer {
  margin-left: 50px;
  display: flex;
}
.title {
  font-size: 20px;
  margin-left: 26px;
}
.menu {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.filters {
  display: flex;
  align-items: center;
  padding: 4px 10px 0 10px;
  margin-right: 8px;
}

.filters:hover {
  border-radius: 8px;
  background-color: #E9E9E9;
}

.icon {
  margin-right: 10px;
}

.logo-cell {
  display: flex;
  align-items: center;
  gap: 4px;
}
