@import url("https://use.typekit.net/ymr4zmt.css");
body {
  margin: 0;
  font-family: "Inter", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.quency-cf-normal {
  font-family: "quincy-cf", serif !important;
  font-weight: 400;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #acabab;
  border-radius: 5px;
}
