.groups-hotels-list {
  display: flex;
  flex-direction: column;
  //overflow: hidden;
  flex: 2;
  height: 100%;
  //border-left: 1px solid #e0e0e0;
  border-radius: 4px 4px 10px 10px;
  background-color: #fff;

  @media (max-width: 1024px) {
    padding: 10px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px;

    h3 {
      color: #333;
      margin: 0;
      flex-grow: 1;
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    span {
      padding-left: 15px;
      font-weight: bold;
      color: #666;
    }

    // A wrapper for the buttons to align them together on the right
    .button-wrapper {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;  // Add space between "Ungroup Selected" button and the icon
      }
    }
  }

  .activeUnGroupBtn {
    border-radius: 20px;
    padding: 1px 16px;
    font-size: 10px;
    color: #000000;
    border-color: #61D5A9;
    background-color: #61D5A9;
  }

  .disabledUnGroupBtn {
    border-radius: 20px;
    padding: 1px 16px;
    font-size: 10px;
    color: #BAB7B7;
    border-color: #BAB7B7;
    background-color: #FFFFFF;
  }

  .cancelBtn {
    border-radius: 20px!important;
    padding: 2px 16px!important;
    font-size: 10px!important;
    color: #000000!important;
    border-color: #BAB7B7!important;
    background-color: #FFFFFF!important;
  }

  .hotels-table-wrapper {
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

}
