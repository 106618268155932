.chip {
  background-color: #61d5a9;
  margin-right: 4px;
}
.flex {
  display: flex;
}
.sublabel {
  color: #c4c4c4;
  font-size: 10px;
  font-weight: 400;
}

.searchAdsContainer {
  background-color: #FAFAFA;
  padding-top: 16px;
  padding-inline: 18px;

  .newCampaignButton {
    font-family: "Inter";
    background-color: #bab7b7 !important;
    color: black;
    border-radius: 20px !important;
    padding: 4px 8px !important;
    font-size: 12px !important;
    width: 134px !important;
    height: 30px !important;
    font-weight: 500 !important;
    text-transform: none;
    display: flex;
    align-items: center;

    &:hover {
      background-color: white !important;
    }
  }
}

.newCampaignButton {
  font-family: "Inter";
  background-color: #bab7b7 !important;
  color: black;
  border-radius: 20px !important;
  padding: 4px 8px !important;
  font-size: 12px !important;
  width: 134px !important;
  height: 30px !important;
  font-weight: 500 !important;
  text-transform: none;
  display: flex;
  align-items: center;

  &:hover {
    background-color: white !important;
  }
}
