.budget-cap-modal-campaign-manager {

  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    border-radius: 10px;
  }

  .monthsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 5px;
  }

  .monthLabel {
    color: black;
    text-align: right;
    /* Smaller Label */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 12px;
    min-width: 4ch;
    text-align: start;
  }

  .months {
    display: "flex";
    margin-right: "24px";
    align-items: center;
  }

  .budgetSubTitle {
    color: black;
    margin-bottom: 8px;
    /* Little Label */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .checkboxContainer {
    display: flex;
    margin-bottom: 24px;
  }

  .budget-info {
    font-size: 12px;
    color: black;
    font-family: "Inter, sans-serif";
    font-weight: 400;

    /* 01 Small Label */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }

  .dialogTitle {
    color: black;

    /* H3 */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 24px;
  }
}
