.search-ads-form-campaign-settings {
  .paper {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }

  .css-1ps6pg7-MuiPaper-root {
    box-shadow: none;
  }

  .tableContainer
    .MuiTableBody-root
    .MuiTableRow-root:last-child
    .MuiTableCell-root {
    border-bottom: none;
  }

  .header {
    display: flex;
    align-items: center;
    background-color: #ebebeb;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-family: Inter;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    height: 36px;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
  }

  .tableContainer {
    max-height: 640px !important;
    font-size: 9px;
    padding: 0px 10px;
    box-sizing: border-box;
  }

  .tableCell {
    cursor: pointer;
    max-width: 114px;
    height: 30px;
  }

  .readOnlyTextField,
  .selectField {
    width: 125px;
  }

  .selectField {
    height: 30px;
  }

  .selectInputPadding,
  .textFieldInputPadding {
    padding: 4px 10px;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 16px;
  }

  .currencyLabel {
    margin-left: 8px;
    font-size: 0.75rem;
    line-height: 24px;
    color: #bab7b7 !important;
  }

  .dialogActionsBtns {
    justify-content: flex-start !important;
    margin-left: 12px !important;
  }

  .dialogButton {
    text-transform: capitalize !important;
    padding: 2px 8px !important;
    margin-bottom: 10px !important;
    height: 25px !important;
    width: 85px !important;
    border-radius: 20px !important;
    font-size: 10px !important;

    &.dialogButtonCancel {
      background-color: #bab7b7 !important;
      color: #000000 !important;
    }

    &.dialogButtonApply:hover {
      background-color: #12794f !important;
      color: #ffffff !important;
    }

    &.dialogButtonCancel:hover {
      background-color: #12794f !important;
      color: #ffffff !important;
    }

    &.cancelHovered {
      background-color: #bab6b6 !important;
      color: gray !important;
    }
  }

  .rsvDialogTitle {
    font-size: 0.75rem !important;
    text-transform: uppercase !important;
  }

  .modalCloseIcon {
    position: absolute !important;
    right: 4px !important;
    top: 4px !important;
    padding: 4px !important;
  }

  .custom-datepicker {
    width: 150px !important;
    margin-top: 5px !important;
    font-size: 10px !important;

    input {
      height: 30px;
      line-height: 30px;
      font-size: 10px;
      padding: 4px;
    }
  }

  /* Scrollbar */
  .reservationContainerBox {
    &::-webkit-scrollbar {
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #bab7b7;
    }

    &::-webkit-scrollbar-thumb {
      background: #bab7b7;
      border-radius: 10px;

      &:hover {
        background: #000000;
      }
    }
  }

  .MuiTableCell-root.totalSelection:first-child {
    padding-left: 14px !important;
    text-align: left;
  }
}
