.alert-action-menu {
    position: absolute;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 160px;
    padding: 10px 0;
    z-index: 1000;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    ul li {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        cursor: pointer;
        color: #333;
        font-size: 12px;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: #f5f5f5;
        }

        .icon {
            margin-right: 10px;
            width: 16px;
            height: 16px;
        }

        &:nth-child(2) {
            color: black;

            .icon {
                fill: #43b581;
            }
        }

        &:nth-child(3) {
            color: #e74c3c;

            .icon {
                fill: #e74c3c;
            }
        }
    }
}
