.bidding-panel-container {
    box-sizing: border-box;
    min-height: 100vh;
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 8px 0px 0px 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .arrow-icon img {
        transition: transform 0.3s ease;
    }

    .rotate-up {
        transform: rotate(180deg);
    }

    .rotate-down {
        transform: rotate(0deg);
    }

    .close-panel-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid #bab7b7;
        padding: 5px;
        cursor: pointer;
        margin-right: 10px;
        box-sizing: border-box;

        &:hover {
            border: 1px solid black;

            > :first-child {
                fill: black;
            }
        }
    }

    .close-panel-icon{
        fill: #BAB7B7;

        &:hover{
            fill: black;
        }
    }

    .panel-header {
        gap: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }


    .form-container .platform-container {
        border-right: 1px solid #61D5A9;
        border-left: 1px solid #61D5A9;
        border-bottom: 1px solid #61D5A9;
        border-radius: 8px;
        width: 100%;
    }

    .bidding-center-platform-selection {
        border-right: 1px solid #61D5A9;
        border-left: 1px solid #61D5A9;
        border-radius: 8px;
        width: 100%;
    }

    .form-container {
        width: 100%;

        .bidding-center-collapsible-header {
            border-bottom: none;
        }
    }

    .bidding-rule-currency{
        display: flex;
        align-items: center;
        font-family: "Inter";
        font-weight: 400;
        font-size: 12px;
        line-height: 14.52px;
        color: #373f41 ;
    }

    .collapsible-section {
        width: 100%;
        padding: 8px;
        background: #FAFAFA;
        border-bottom: 1px solid #61D5A9;
        border-radius: 8px;
        box-sizing: border-box;
    }

    .collapsible-form {
        width: 100%;
        margin-top: 5px;
    }

    .bidding-center-collapsible-header {
        border-bottom: 1px solid #61d5a9;
        border-top: 1px solid #61D5A9;
        border-radius: 8px;
        padding: 10px;
        background: white;
        height: 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.expanded {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 1px solid #61D5A9;
        }
    }

    .platform-title {
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", serif;
        color: black;
    }

    .linked-hotel-button{
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px;
        height: 24px;
        white-space: nowrap;
        font-size: 10px;
        font-weight: 400;
        font-family: "Inter";
    }

    .bidding-panel-container h2 {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: center;
    }

    .arrow-icon {
        display: flex;
    }

    .collapsible-content {
        margin-top: 10px;
    }

    .checkbox-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        justify-content: center;
    }

    .checkbox-group {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .checkbox-group label {
        font-size: 1.2rem;
        color: #333;
        font-weight: 500;
        margin-bottom: 8px;
        text-align: left;
    }

    .checkbox-group .column {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 10px;
    }

    .checkbox-group button {
        background-color: white;
        border-radius: 20px;
        padding: 10px 20px;
        border: 1px solid #ccc;
        cursor: pointer;
        font-size: 10px;
        color: #555;
    }

    .checkbox-group button.selected {
        background-color: #d5f5e3;
        border-color: #32d69f;
        color: #32d69f;
    }

    /** checkbox */
    .checkbox-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .mul-form-label {
        font-size: 1.2rem;
        font-weight: 500;
        color: #333;
        margin-bottom: 8px;
    }

    .custom-button {
        font-family: 'Inter';
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 calc(25% - 10px);
        padding: 10px 0;
        height: 24px;
        border-radius: 25px;
        border: 1px solid #ccc;
        background-color: white;
        color: #BAB7B7;
        font-size: 10px;
        cursor: pointer;
        text-align: center;
        box-sizing: border-box;
        max-width: calc(25% - 10px);

        &.selected {
            background-color: #12794F;
            color: white;
        }
    }

    .custom-button:hover {
        border: 1px solid black;
        color: black;
    }
}
