@import '../../../styles/variables';

/* Container Styles */
.groups-list-container {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
}

/* Header Styles */
.groups-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: $font-color; /* Darker font color */
  }

  h3 {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: $font-color; /* Darker font color */
  }
}

/* Input Styles */
.search-groups-input {
  width: 70%;
  padding: $input-padding;
  margin-right: 10px;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  font-size: $font-size;
}

/* Add Button Styles */
.add-group-btn {
  background-color: inherit;
  color: $input-border-color;
  border-color: $input-border-color;
  padding: 2.5px 6px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: $input-border-color;
    color: white;
  }
}

/* Group List Styles */
.groups-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  flex-grow: 1;
  overflow-y: auto; /* Allow scrolling if the list grows */
  scrollbar-width: $scrollbar-width;

  li {
    padding: $group-item-padding;
    border-bottom: 1px solid $border-color;
    cursor: pointer;
    font-size:12px;
    font-family: "Roboto";
    font-weight: 400;
    color: #000000;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #FAFAFA; /* Hover effect */
    }
  }
}

/* Ensure the container takes the full height */
html, body {
  margin: 0;
  height: 100%;
}

.group-form {
  .activeSubmitBtn {
    border-radius: 20px !important;
    padding: 1px 16px !important;
    font-size: 10px !important;
    color: #000000 !important;
    border-color: #61D5A9 !important;
    background-color: #61D5A9 !important;
  }

  .cancelSubmitBtn {
    border-radius: 20px !important;
    padding: 2px 16px !important;
    font-size: 10px !important;
    color: #000000 !important;
    border-color: #BAB7B7 !important;
    background-color: #FFFFFF !important;
  }

  .newGroupBtn {
    border-radius: 20px !important;
    padding: 1px 16px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    font-family: Inter, sans-serif !important;
    color: #000000 !important;
    border-color: #61D5A9 !important;
    background-color: #61D5A9 !important;
  }
}
