.bid-cell {
  text-decoration: underline;
  text-decoration-color: #61d5a9;
  text-decoration-thickness: 2px;
  text-decoration-style: dashed;
  line-height: 16px;
  cursor: pointer;
}

.edit-icon-table-row {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

tr:hover .edit-icon-table-row {
  visibility: visible;
  opacity: 1;
}

.clickable-element:hover{
  text-decoration: underline;
}