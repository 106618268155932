.groupTableHeaderTop {
  padding: 0 14px 0 14px;
  align-items: center;
  background-color: #ebebeb;
  height: 46px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }
}
