.pmax-google-form-accordion {
  border: 1px solid #61D5A9;
  border-radius: 10px !important;
  box-shadow: none !important;
  overflow: hidden;
  margin-bottom: 5px !important;

  &.Mui-expanded {
    margin-top: 5px !important;
  }

  &.visited {
    background-color: #61D5A9;
    .accordion-summary {
      color: black;
    }
  }

  .accordion-summary {
    cursor: default;
    p {
      font-size: 12px;
      color: #373F41;
      font-family: Inter;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .accordion-content {
    padding: 20px;
    background-color: #FAFAFA;
  }

  .nextButton {
    width: 100px;
    height: 24px;
    border-radius: 12px !important;
    background-color: #12794F !important;
    text-transform: capitalize !important;
    color: white !important;
    margin-top: 20px !important;
  }

  .saveButton {
    width: 100px;
    height: 24px;
    border-radius: 12px !important;
    background-color: #12794F !important;
    text-transform: capitalize !important;
    color: white !important;
    margin-top: 20px !important;
    margin-left: 10px;
  }
}
