.search-ads-form-objective {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .objectiveHeading {
    font-size: 18px;
    font-weight: bold;
  }

  .search-ads-form-objective-buttons {
    margin-top: 15px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .objectiveButton {
      padding: 75px 100px;
      border-radius: 10px;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: Inter, sans-serif;
      overflow: hidden;
      text-align: center;

      div {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
        white-space: nowrap;
      }

      small {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        text-transform: capitalize;
        white-space: normal;
      }

      &.selected {
        background-color: #12794F;
        color: #fff;

        div, small {
          color: #fff;
        }
      }
    }
  }
}
