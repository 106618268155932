.chartComponent {
  background-color: white;
  border-radius: 10px;
  min-height: 260px;
  max-height: 260px;

  .chartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    &.collapsed {
      .title {
        font-size: 20px;
        margin-left: 26px;
      }
    }

    .menuContainer {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      margin-left: auto;

      .menu {
        display: flex;
        align-items: center;
        margin-right: 30px;

        &.hidden {
          display: none;
        }
      }
    }

    .filters {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      margin-right: 30px;
      cursor: pointer;

      &:hover {
        border-radius: 8px;
        background-color: #e9e9e9;
      }
    }

    .icon {
      margin-right: 10px;
    }
  }

  .chartBody {
    width: 100%;
    height: 200px;
    display: block;

    &.hidden {
      display: none;
    }
  }

  .select {
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}