.alert-center-page {

    .MuiAutocomplete-listbox {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

    .MuiAutocomplete-option {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
    }

    .MuiList-root span{
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 12px;
    }
}

.alert-form-container {

    .query-builder-result {
        display: block;
    }

    .alert-form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 20px;
        padding: 5px 15px 15px;
        background-color: #fafafa;
        border-radius: 10px;
        justify-content: space-between;
    }

    .MuiListItemText-primary{
        font-size: 12px !important;
        color: #373f41;
    }

    .query-builder .MuiInputBase-root.MuiInput-root {
        border: 1px solid #C4C4C4;
        background: white;
        height: 30px;
        border-radius: 4px;
        padding: 4px 8px;

        transition: border-color 0.3s;
    }

    .query-builder .MuiInput-underline:before,
    .query-builder .MuiInput-underline:hover:before,
    .query-builder .MuiInput-underline:after {
        border-bottom: none;
    }

    .query-builder .MuiInputBase-root.MuiInput-root:hover {
        border-color: #61D5A9;
    }


    .query-builder .MuiInputBase-root.MuiInput-root.Mui-focused {
        border: 1px solid #61D5A9;
    }

    .query-builder .MuiInputBase-input::placeholder {
        font-size: 12px;
        font-weight: 400;
        font-family: Inter, Serif;
        line-height: 12.1px;
    }



    .query-builder .MuiInputLabel-root {
        background-color: white;
        padding: 0 4px;
        transform: translate(14px, -6px) scale(0.75);
    }

    .input-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
    }

    .bottom-top-side {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 30px;
    }

    .button-container {
        display: flex;
        gap: 15px;
        flex-direction: row;
    }

    .additional-columns-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 45%;
    }

    .segment-list-container {
        margin-right: 60px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .segment-list-label {
        display: flex;
    }

    .segment-list-checkboxes {
        display: flex;
        flex-direction: column;

        span{
            padding-right: 0 !important;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }
    }

    .email-container {
        margin-top: 10px;
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: column;
    }

    .selected-hotels-view{
        display: flex;
        padding-right: 15px;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
    }

    .query-builder {
        margin: 0 !important;
        padding-top: 5px;
        width: 100%;
        /* Styles provenant de .alert-form-container */
    }

    .rule.group-or-rule{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder .query-builder .MuiButtonBase-root:hover {
        background: #61D5A9;
        border-color: #61D5A9;
    }

    .query-builder .MuiButton-containedPrimary {
        background-color: #61D5A9 !important;
        color: black !important;
    }

    .query-builder .rule {
        background-color: #fafafa;
        border: 1px solid #61D5A9;
        padding: 5px;
    }

    .query-builder-container {
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 5px;
        background-color: #fafafa;
        border-radius: 8px;
    }

    .query-builder .group {
        background: #fafafa;
        border-color: #fafafa;
    }

    .bottom-side {
        display: flex;
        flex-direction: column;
        width: 55%;
    }

    .top-side {
        display: flex;
        flex-direction: column;
    }

    .left-section {
        padding-left: 50px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

     .MuiInputBase-root {
        font-size: 12px;
    }

    /* Tagify */

    .tagify__tag__removeBtn {
        font-size: 12px;
    }

    .tagify__tag:hover:not([readonly]) div::before {
        background-color: #61D5A9;
    }

    .tagify__tag:focus:not([readonly]) div::before {
        background-color: #61D5A9;
    }

    .tagify {
        --tags-border-color: none !important;
        border-top: none;
        border-left: none;
        border-right: none;
        display: inline-block;
        position: relative;
        --tag-border-radius: 12px;
        width: 100%;
        padding: 6px 0 7px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
        --tag-pad: 0.2em 0.5em;
        &.error-tagify{
            border-bottom: 1px solid #d32f2f !important;
        }
    }

    .tagify__tag {
        height: 24px;
        font-size: 12px;
        --tags-border-radius: 12px;
        --tag-bg: #BAB7B7;
        --tag-hover: #61D5A9;
        margin-right: 10px;
    }

    .tagify--focus {
        box-sizing: border-box;
        border-bottom: 2px solid #61D5A9 !important; /* Changement de la couleur de la bordure au focus */
        &.error-tagify{
            border-bottom: 2px solid #d32f2f !important;
        }
    }

    .tagify--focus:hover {
        border-bottom: 2px solid #61D5A9 !important; /* Changement de la couleur de la bordure au focus */
        &.error-tagify{
            border-bottom: 1px solid #d32f2f !important;
        }
    }


    .tagify__input {
        border: none !important;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        margin-left: 0;
        font-size: 12px;
        width: auto;
        background: transparent;
        flex: 1;
        min-width: 120px;
    }

    .tagify__input:focus {
        outline: none;
    }

    .tagify__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }


    .alert-form > div {
        flex: 1 1 48%; /* Compact fields */
    }

    .alert-form label {
        color: black;
        font-family: Inter, Serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 12.1px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

     .alert-form .segment-list-checkboxes label {
        color: black;
        font-family: Inter, Serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 12.1px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    .left-section input,
    .right-section input,
    #tags-outlined,
    .alert-form select {
        padding: 6px; /* Reduced padding */
        width: 315px;
    }

    /* Compact checkbox styling */
    .segment-list-checkboxes input[type="checkbox"] {
        width: auto;
        margin-right: 8px;
    }

    /* Metric Rules section */
    .metric-rules {
        margin-top: 15px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 6px;
        background-color: #fefefe;
    }


    /* Buttons styling */
    .query-builder button {
        box-sizing: border-box;
        font-size: 12px;
        font-weight: 400;
        font-family: Inter, Serif;
        color: #373f41;
        padding: 10px 15px;
        cursor: pointer;
    }

    .query-builder-container .query-builder .MuiSvgIcon-root {
        color: #bab7b7;
    }

    .query-builder .MuiButton-containedPrimary {
        box-sizing: border-box;
        border: 2px solid #e0e0e0 !important;
        border-radius: 5px  ;
        width: 40px;
        height: 30px;
    }

    .query-builder .MuiButton-containedInherit{
        border-radius: 5px;
        border: 1px solid #e0e0e0 !important;
        width: 40px;
        height: 30px;
    }

    .bidding-rule-form .query-builder-container .MuiAutocomplete-option {
        font-size: 12px !important;
    }

    .alert-form .save-button {
        padding: 10px 25px; /* Larger buttons */
    }

    .alert-form .cancel-button {
        padding: 10px 25px; /* Larger buttons */
    }

    /* Metric Rules buttons (AND/OR) adjustments */
    .query-builder .group--actions {
        padding: 10px 0;
    }

    .query-builder-container .query-builder .MuiSelect-select {
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder-container .query-builder .MuiInputBase-input {
        font-size: 12px !important;
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder-container .query-builder .MuiButtonGroup-root {
        gap: 10px;
    }



    .group--actions button {
        color: black;
    }

    .query-builder .btn--add-rule,
    .query-builder .btn--add-group {
        background-color: #6c757d;
        border-radius: 4px;
        padding: 6px 12px;
        color: white;
    }

    .query-builder .operator--btn {
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        padding: 6px;
        border-radius: 4px;
    }

    .query-builder select {
        padding: 5px 8px;
        border-radius: 4px;
    }

    .rule--body--wrapper{
        display: flex;
        justify-content: center;
    }

    /** Selected Hotels */
    .selected-hotels {
    }

    .selected-hotels-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .hotel-badge {
        background-color: #f5f5f5;
        padding: 10px;
        height: 30px;
        align-items: center; /* Align items vertically */
        justify-content: space-between; /* Space between text and the cross */
        border-radius: 8px;
        display: flex;
        box-shadow: 0 2px 5px rgba(75, 73, 73, 0.65);
        min-width: 150px;
        transition: background-color 0.2s ease;
    }

    .hotel-badge:hover {
        background-color: #e0f7fa;
    }

    .remove-hotel-button {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        color: red;
        cursor: pointer;
        font-size: 18px;
    }

    .remove-hotel-button:hover {
        color: darkred;
    }

    .alert-center-small-checkbox {
        width: 5px;
        height: 5px;
    }

}