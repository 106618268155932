.search-ads-form-campaign-settings {
    * {
      font-family: 'Inter';
    }
  
    .sectionTitle {
      font-size: 12px;
      font-weight: 400;
      color: #333;
      margin: 15px;
      text-transform: uppercase;
    }
  
    .selectActions {
      display: flex;
      align-items: center;
      gap: 16px;
  
      .MuiFormControlLabel {
        margin-right: 16px;
      }
  
      .MuiFormControlLabel-label {
        font-weight: 700;
      }
    }
  
    .countrySearchField {
      background-color: transparent;
      padding-left: 8px;
      margin-right: 16px;
  
      .MuiInputBase-root {
        font-size: 0.875rem;
        color: gray;
        height: 32px;
        padding-top: 4px;
        padding-bottom: 4px;
      }
  
      .MuiInputBase-root::before {
        border-bottom: 1px solid lightgray;
      }
  
      .MuiInputBase-root:hover::before {
        border-bottom: 1px solid darkgray;
      }
  
      .MuiInputBase-root.Mui-focused::before {
        border-bottom: 1px solid darkgray;
      }
  
      .MuiInputAdornment-root {
        color: gray;
      }
  
      .MuiInputBase-input::placeholder {
        color: lightgray;
        opacity: 1;
      }
    }
  
    .regionGroups {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 10px;
    }
  
    .continentLabel {
      .MuiFormControlLabel-label {
        font-weight: 700 !important;
        font-size: 12px;
      }
    }
  
    .countryGroup {
      margin-bottom: 16px;
    }
  
    .monthlyBudgetInputs {
      display: grid;
      gap: 16px;
  
      .monthField {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
  
        .monthLabel {
          min-width: 80px;
          text-align: right;
        }
  
        .monthInput {
          flex-grow: 1;
        }
      }
    }

    .MuiFormControlLabel-label {
      font-family: 'Inter';
      font-size: 10px;
      color: #333;
    }
  
    .loadMoreButton {
      text-transform: none;
      color: gray;
      font-size: 0.9rem;
      font-weight: normal;
  
      &:hover {
        background-color: transparent;
      }
    }
  
    .no-border-focus {
      input {
        height: 20px;
        border-radius: 10px;
      }
  
      & .MuiOutlinedInput-notchedOutline {
        border-color: lightgray;
      }
  
      &:hover .MuiOutlinedInput-notchedOutline,
      &.Mui-focused .MuiOutlinedInput-notchedOutline,
      &:focus-visible .MuiOutlinedInput-notchedOutline {
        border-color: lightgray !important;
      }
  
      .MuiSelect-select {
        color: black;
        background-color: white;
        height: 20px;
        padding: 3px;
        text-align: left;
        vertical-align: center !important;
  
        &:hover,
        &:focus,
        &:active,
        &:visited {
          color: black;
        }
      }
  
      &.Mui-focused .MuiSelect-select {
        color: black !important;
      }
    }
  
    .custom-helper-text {
      font-size: 8px;
      margin: 0;
      padding-left: 5px;
      padding-top: 5px;
      font-family: Inter, sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      background-color: #F2F2F2;
    }
  }
  