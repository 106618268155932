.template-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 15px;

    label{
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        color: black;
    }

    .MuiInputBase-root {
        font-size: 12px;
    }

    .bidding-rule-template-name-container {
        gap: 3px;
        font-weight: 400;
        width: 30%;
        font-size: 12px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .bidding-rule-name-row {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .bidding-rule-name-container .MuiTextField-root {
        flex-grow: 1;
    }

    .bidding-rule-metric-analysis-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: space-between;
    }

    .import-bidding-template-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .button-container {
        display: flex;
        gap: 15px;
        flex-direction: row;
    }

    .rule--body--wrapper{
        display: flex;
        justify-content: center;
    }

    .query-builder-result {
        display: block;
    }

    .query-builder {
        margin: 0 !important;
        padding-top: 5px;
        width: 100%;
    }

    .query-builder .MuiInputBase-root.MuiInput-root {
        border: 1px solid #C4C4C4;
        background: white;
        height: 30px;
        border-radius: 4px;
        padding: 4px 8px;
        transition: border-color 0.3s;
    }

    .rule.group-or-rule{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder button {
        box-sizing: border-box;
        font-size: 12px;
        font-weight: 400;
        font-family: Inter, Serif;
        color: #373f41;
        padding: 10px 15px;
        cursor: pointer;
    }

    .query-builder-container .query-builder .MuiSvgIcon-root {
        color: #bab7b7;
    }

    .query-builder .MuiButton-containedPrimary {
        box-sizing: border-box;
        border: 2px solid #e0e0e0 !important;
        border-radius: 5px  ;
        width: 40px;
        height: 30px;
    }

    .query-builder .MuiButton-containedInherit{
        border-radius: 5px;
        border: 1px solid #e0e0e0 !important;
        width: 40px;
        height: 30px;
    }

    .query-builder-container .query-builder .MuiSelect-select {
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder-container .query-builder .MuiInputBase-input {
        font-size: 12px !important;
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .query-builder-container .query-builder .MuiButtonGroup-root {
        gap: 10px;
    }

    .query-builder .MuiInput-underline:before,
    .query-builder .MuiInput-underline:hover:before,
    .query-builder .MuiInput-underline:after {
        border-bottom: none;
    }

    .query-builder .MuiInputBase-root.MuiInput-root:hover {
        border-color: #61D5A9;
    }

    .query-builder .MuiInputBase-root.MuiInput-root.Mui-focused {
        border: 1px solid #61D5A9;
    }

    .query-builder .MuiInputBase-input::placeholder {
        font-size: 12px;
        font-weight: 400;
        font-family: Inter, Serif;
        line-height: 12.1px;
    }

    .query-builder .MuiInputLabel-root {
        background-color: white;
        padding: 0 4px;
        transform: translate(14px, -6px) scale(0.75);
    }

    .query-builder .query-builder .MuiButtonBase-root:hover {
        background: #61D5A9 !important;
        border-color: #61D5A9 !important;
    }

    .query-builder .MuiButton-containedPrimary {
        background-color: #61D5A9 !important;
        color: black !important;
    }

    .query-builder .rule {
        background-color: #FAFAFA;
        border: 1px solid #61D5A9 !important;
        padding: 5px;
    }

    .query-builder .group {
        background: #FAFAFA !important;
        border-color: #FAFAFA !important;
    }

    .query-builder-container {
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, Serif;
        line-height: 12.1px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 5px;
        background-color: #FAFAFA;
        border-radius: 8px;
    }

    .query-builder .group .group-or-rule {
        background: #FAFAFA;
        border-color: #FAFAFA;
    }

    .query-builder .group--actions {
        padding: 10px 0;
    }

    .group--actions button {
        color: black;
    }

    .query-builder .btn--add-rule,
    .query-builder .btn--add-group {
        background-color: #6c757d;
        border-radius: 4px;
        padding: 6px 12px;
        color: white;
    }

    .query-builder .operator--btn {
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        padding: 6px;
        border-radius: 4px;
    }

    .query-builder select {
        padding: 5px 8px;
        border-radius: 4px;
    }

    /* Si nécessaire, ajouter d'autres styles spécifiques à .template-form */
}
