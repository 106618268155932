.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.checkbox-group {
  margin-bottom: 10px;
}

.warning-text {
  color: red; /* Red text for the warning message */
  font-size: 10px;
  font-family: Inter, sans-serif;
  margin: 5px 5px 15px 25px;
}

.checkbox-options {
  margin-top: 15px;
}

.MuiButton-root.cancelBtn {
  border-radius: 20px!important;
  padding: 2px 16px!important;
  font-size: 10px!important;
  color: #000000!important;
  border-color: #BAB7B7!important;
  background-color: #FFFFFF!important;
}

.MuiButton-root.activeCnfBtn {
  border-radius: 20px!important;
  padding: 2px 16px!important;
  font-size: 10px!important;
  color: #000000!important;
  border-color: #61D5A9!important;
  background-color: #61D5A9!important;
}

.MuiButton-root.inActiveCnfBtn {
  border-radius: 20px!important;
  padding: 2px 16px!important;
  font-size: 10px!important;
  color: #BAB7B7!important;
  border-color: #BAB7B7!important;
  background-color: #FFFFFF!important;
}
