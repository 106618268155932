body {
  margin: 0;
  padding: 0;
}

.custom-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-image: url('../../assets/images/background.png');
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}

.internal-block {
  max-width: 650px !important;
  height: 400px;
  border-radius: 30px;
  background: #FFFFFF;
  display: flex;


  .box-1 {
    flex: 1;
    padding: 40px 20px 20px 20px;
    text-align: left;
    margin-bottom: 10px;

    .layout-heading {
      cursor: unset;
    }

    .layout-desc {

    }
  }

  .box-2 {
    flex: 1;
    padding: 40px 20px 20px 20px;
    text-align: left;

    .login-heading {
      font-size: 24px;
      font-weight: 400;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.container {
  display: flex;

  > svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-right: 10px;
    margin-bottom: 5px;

    &:nth-child(5n + 1) {
      margin-bottom: 0;
    }
  }
}

.logo {
  position: absolute;
  top: 43px;
  left: 59px;
  width: 100px;
  height: auto;
}