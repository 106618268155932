.pMaxSignUpPage {
  padding: 41px 0 0 31px;

  .header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .header-left {
    display: flex;
    align-items: center;
  }

  .icon-wrapper {
    margin-right: 10px;
  }

  .sub-heading {
    font-family: 'Quincy CF';
    font-weight: 400;
    font-size: 14px;
    color: #587A78;
    font-style: italic;
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #BAB7B7;
    margin-top: -10px;
  }

  .summary-section {
    width: 1100px;
  }

  .summary-title {
    font-size: 11px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 13.31px;
    margin: 41px 0 21px;
    color: var(--Grey, #373F41);
  }

  .summary-text-bold {
    font-size: 11px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 13.31px;
    color: var(--Grey, #373F41);
  }

  .summary-text {
    color: var(--Grey, #373F41);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 40px;
  }
}