@import '../../../../styles/variables';

.custom-hotels-table-container {
  width: 100%; /* Ensure the table takes up the full width of the container */
  height: 100%; /* Ensure the table takes up the full height of the container */
  display: flex;
  flex-direction: column; /* Allow the table to grow within the container */
  justify-content: space-between;

  [class*="MuiTableRow-root"]{
    box-shadow: none!important;
  }

}

.buttons-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.activeGroupBtn {
  border-radius: 20px;
  padding: 1px 16px;
  font-size: 10px;
  color: #000000;
  border-color: #61D5A9;
  background-color: #61D5A9;
}

.disabledGroupBtn {
  border-radius: 20px;
  padding: 1px 16px;
  font-size: 10px;
  color: #BAB7B7;
  border-color: #BAB7B7;
  background-color: #FFFFFF;
}