.page-titles-container {
  display: flex;
  justify-content: space-between;
  padding-inline: 16px;

  .title-wrapper {
    display: flex;
    align-items: baseline;
  }

  .subtitle {
    border-left: 1px solid #BAB7B7;
    color: #BAB7B7;
    padding-left: 7px;
    margin-left: 18px;
    font-size: 12px;
  }

}



