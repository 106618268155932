.custom-dropdown {
  .MuiSelect-select,
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  .MuiSelect-select {
    padding: 0 !important;
    margin: 1.5px 0 !important;
    text-align: left;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: black;
    text-transform: capitalize;
  }
}

.select-search {
  margin: 0 10px 5px !important;

  .MuiOutlinedInput-notchedOutline {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: 1px solid #bab7b7;
    padding: 12px !important;
  }
}
.selected-label {
  font-size: 12px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  .accountHoverState {
    height:25px;
    display:flex;
    align-items:center;
    width: 415px;
    margin-top: -2px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 400;
    font-family: Inter, sans-serif;
  }
}
.selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 170px;
}
