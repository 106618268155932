@import '../../../styles/variables';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 12px;
  max-width: 500px;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add some shadow */

  .modal-actions {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .loader-modal {
      text-align: right;
      margin-right: 10px;
      display: inline-block;

      span {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  /* HotelTable Styles */
  .group-form {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h3 {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    label {
      font-size: small;
      padding-left: 3px;
    }

    p {
      margin: 0px;
    }

    input {
      padding: $input-padding;
      border-radius: $input-border-radius;
      border: 1px solid $border-color;
    }
  }
}

.modal-close {
  display: none;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.MuiButton-root.deleteBtn {
  border-radius: 20px!important;
  padding: 2px 16px!important;
  font-size: 10px!important;
  color: #000000!important;
  border-color: #61D5A9!important;
  background-color: #61D5A9!important;
}

.MuiButton-root.cancelBtn {
  border-radius: 20px!important;
  padding: 2px 16px!important;
  font-size: 10px!important;
  color: #000000!important;
  border-color: #BAB7B7!important;
  background-color: #FFFFFF!important;
}