.metasearch-reporting-component-table {
  background-color: #FFFFFF;
  border-radius: 10px;
  min-height: 260px;
  max-height: 260px;

  .metasearch-reporting-component-table-container {
    border-radius: 10px;
    box-shadow: none !important;

    * {
      font-family: Inter;
    }

    th, td {
      padding: 13px 8px;
      border-bottom: 1px solid rgba(204, 204, 204, 1);
    }

    .table-metasearch-reporting-header {
      font-weight: 600;
      font-family: Inter, sans-serif;
      font-size: 12px;
      text-align: right;

      &.title-channel {
        text-align: left !important;
      }
    }

    .MuiTableSortLabel-root {
      display: inline-flex;
      align-items: center;
      gap: 4px;
    }

    .MuiTableSortLabel-icon {
      font-size: 11px;
      margin-left: 4px;
    }

    .table-metasearch-reporting-bottom {
      &.total {
        text-align: left !important;
      }

      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 13.31px;
      text-align: right;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      border-bottom: none;
    }

    .table-metasearch-reporting-body {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 11.72px;
      text-align: right;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .table-metasearch-reporting-body-left {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
}
