@import '../../../styles/variables';

.hotels-list {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 40%;
  border-radius: 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter", sans-serif;

      .hotel-count {
        font-size: 12px;
        font-weight: normal;
        color: grey;
        margin-left: 5px;
        position: relative;
        top: -5px;
      }
    }
  }

  .hotels-table-wrapper {
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

}
