.top-markets-component {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  min-height: 459px;
  max-height: 459px;

  .top-markets-container {
    &:not(.loading) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    padding-bottom: 20px;

    .top-market-map-container {
      display: flex;
      justify-content: center;
    }

    .top-markets-table-container {
      padding-top: 0 !important;

      .top-markets-table-header {
        .option-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-inline: 15px;
        }
      }

      .top-markets-table {
        padding-top: 5px;

        .market-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-inline: 15px 30px;
          padding-block: 7px;
          border-bottom: 1px solid #dcdcdc;

          &.no-data {
            font-size: 12px;
            font-family: Inter, sans-serif;
          }

          .market-name {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 12.1px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          .market-value {
            //styleName: 03 Smallest Label;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 9.68px;
            text-align: right;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
        }
      }
    }
  }
}
