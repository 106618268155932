.alert-center {


    .panel-content {
        background: #FAFAFA;
    }

    .arrow-icon img {
        transition: transform 0.3s ease;
    }

    .rotate-up {
        transform: rotate(180deg);
    }

    .rotate-down {
        transform: rotate(0deg);
    }

    .alert-panel-container {
        height: auto;
        width: 100%;
        background-color: #fafafa;
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: -10px;
    }

    .alert-form-container .platform-container {
        &.expanded {
            border-right: 1px solid #61D5A9;
            border-left: 1px solid #61D5A9;
            border-bottom: 1px solid #61D5A9;
            border-radius: 8px;
        }
    }

    .platform-selection {
        &.expanded {
            border-right: 1px solid #61D5A9;
            border-left: 1px solid #61D5A9;
            border-radius: 8px;
        }
    }


    .alert-form-container {
        width: 100%;
    }

    .header {
        text-align: left;
    }

    .collapsible-section {
        width: 100%;
        padding: 8px;
        background: #FAFAFA;
        border-bottom: 1px solid #61D5A9;
        border-radius: 8px;
    }

    .collapsible-form {
        width: 100%;
        margin-top: 4px;

        &.expanded {
            border-right: 1px solid #61D5A9;
            border-left: 1px solid #61D5A9;
            border-bottom: 1px solid #61D5A9;
            border-radius: 8px;
        }
    }

    .collapsible-header {
        border: 1px solid #61D5A9;
        border-radius: 8px;
        padding-left: 10px;
        padding-right: 10px;
        background: white;
        height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.expanded {
            border-right: none;
            border-left: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .platform-title {
        font-size: 12px;
        font-weight: 400;
        font-family: "Inter", serif;
        color: black;
    }

    .alert-panel-container h2 {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: center;
    }

    .arrow-icon {
        display: flex;
    }

    .collapsible-content {
        margin-top: 10px;
    }

    .group-title{
        color: black;
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, Serif;
        line-height: 14.52px;
    }

    .checkbox-group {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .checkbox-group .column {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 10px;
    }

    .checkbox-group button {
        background-color: #f3f3f3;
        border-radius: 20px;
        padding: 10px 20px;
        border: 1px solid #ccc;
        cursor: pointer;
        font-size: 10px;
    }


    /** checkbox */
    .checkbox-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .mul-form-label {
        font-size: 1.2rem;
        font-weight: 500;
        color: #333;
        margin-bottom: 8px;
    }

    .checkbox-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-start;
    }

    .custom-button {
        font-family: 'Inter';
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 calc(25% - 10px);
        padding: 10px 0;
        height: 24px;
        border-radius: 25px;
        border: 1px solid #ccc;
        background-color: white;
        color: #BAB7B7;
        font-size: 10px;
        cursor: pointer;
        text-align: center;
        box-sizing: border-box;
        max-width: calc(25% - 10px);
    }

    .custom-button:hover {
        border: 1px solid black;
        color: black;
    }

     .custom-button.selected-alert {
        background-color: #12794F;
        color: white;
    }

}
