.reservations {

  .paper {
    width: 97.99%;
    overflow: hidden;
    margin-left: 16px!important;
  }

  .header {
    display: flex;
    align-items: center;
    background-color: #F2F2F2;
    padding: 7px 10px 7px;
    font-size: 14px;
  }

  .tableContainer {
    max-height: 640px !important;
    font-size: 9px;
  }

  .tableCell {
    cursor: pointer;
    max-width: 114px;
    height: 30px;
  }

  .readOnlyTextField,
  .selectField {
    width: 125px;
  }

  .selectField {
    height: 30px;
  }

  .selectInputPadding,
  .textFieldInputPadding {
    padding: 4px 10px;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 16px;
  }

  .currencyLabel {
    margin-left: 8px;
    font-size: 0.75rem;
    line-height: 24px;
    color: #bab7b7 !important;
  }

  .dialogActionsBtns {
    justify-content: flex-start !important;
    margin: 0 0 5px 12px  !important;
  }

  .rsvDialogTitle {
    font-size: 12px !important;
    text-transform: uppercase !important;
  }

  .modalCloseIcon {
    position: absolute !important;
    right: 4px !important;
    top: 4px !important;
    padding: 4px !important;
  }

  .custom-datepicker {
    width: 150px !important;
    margin-top: 5px !important;
    font-size: 10px !important;

    input {
      height: 30px;
      line-height: 30px;
      font-size: 10px;
      padding: 4px;
    }
  }

  /* Scrollbar */
  .reservationContainerBox {
    &::-webkit-scrollbar {
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      &:hover {
        background: #000000;
        height: 2px;
      }
    }
  }

  .MuiTableCell-root.totalSelection:first-child {
    padding-left: 14px !important;
    text-align: left;
  }

  .MuiPaper-root {
   box-shadow: none!important;
    border-radius: 4px!important;
  }

}