.cloudinary-image-editor {
  padding: 20px;
  font-family: 'Inter', sans-serif;
  min-width: 95%;

  .image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .cloudinary-category {
      width: 100%;

      h4 {
        font-family: 'Inter', sans-serif;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text-error-section {
          color: #d32f2f;
          font-size: 10px;
          margin-left: 8px;
          font-family: "Inter", sans-serif;
          font-weight: 500;
        }

        .add-icon {
          font-size: 20px;
          cursor: pointer;
          color: #BAB7B7;
          transition: color 0.3s ease;
          margin-bottom: -5px;

          &:hover {
            color: #333;
          }
        }
      }

      .image-category {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #c0c0c0;
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: #a0a0a0;
        }

        &::-webkit-scrollbar-track {
          background-color: #f9f9f9;
          border-radius: 3px;
        }

        .image-card {
          position: relative;
          flex: 0 0 auto;
          height: 150px;
          border-radius: 5px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f5f5f5;
          transition: box-shadow 0.3s ease, transform 0.3s ease;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &.error {
            border: 1px solid red !important;
          }

          .image-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            opacity: 0;
            transition: opacity 0.3s ease;

            .error-message {
              position: absolute;
              top: 45%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: white;
              padding: 10px;
              font-family: Inter;
              font-size: 8px;
              font-weight: 400;
              text-align: center;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              width: 80%;
              word-wrap: break-word;
              white-space: normal;
              line-height: 1.2;
            }

            &:hover, &.has-error {
              opacity: 1;
            }

            .image-actions {
              position: absolute;
              bottom: 5px;
              right: 2px;
              display: flex;
              gap: 1px;

              .MuiIconButton-root {
                color: white !important;
                background-color: unset;
                border: none;
                font-size: 5px;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: background-color 0.3s ease, color 0.3s ease;

                svg {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  .full-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1500;
    cursor: pointer;

    .full-screen-image {
      max-width: 90%;
      max-height: 90%;
      object-fit: contain;
      border-radius: 8px;
    }
  }
}
