$dark: #587A78;
$black: #000000;

$background-color: #fff;
$grey-color: #C4C4C4;
$grey-color-light: #EAEAEA;
$grey-light: #F4F4F4;
$text-color: #333;
$text-color-dark: #444;
$primary-color: #555;

$white: #ffffff;

$border-color: #e0e0e0;
$input-border-color: #c4c4c4;
$input-border-radius: 4px;
$font-color: #333;
$hover-bg-color: #f0f0f0;
$group-item-padding: 12px 16px;
$input-padding: 8px;
$font-size: 14px;
$modal-button-padding: 8px 16px;
$modal-bg-color: #ddd;
$button-bg-success: #4CAF50;
$scrollbar-width: 8px;

$inter : Inter, sans-serif;

#menu- {
  ul {
    .select-check {
      padding: 16px 16px 0;
    }
    .check-list {
      padding-left: 36px;
      position: relative;
      > span {
        padding: 0;
        gap: 9px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 24px;
        top: 0;
        bottom: 0;
        border-left: 1px solid;
      }
    }
  }
}
