.reservations .formGrid {
  display: flex;
  flex: 2;
  margin-top: 9px;
}

.reservations .form-title {
  color: #aaa;

  /* Smaller Label */
  font-family: Inter;
  margin-top: 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
}
.reservations .form-button-container {
  display: flex;
  align-items: flex-end;
}
.reservations .filter-field-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.reservations .fields-container {
  padding-left: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  flex: 3;
}
.reservations .form-grid-item {
  margin-right: 30px;
  margin-bottom: 15px;
}

.reservations .mul-form-label {
  font-size: 12px!important;
  font-weight: 600!important;
  color: #000000!important;
  font-family: Inter, sans-serif!important;
}
