.heading {
  font-size: 30px !important;
}

.score-title {
  color: #545454;

  /* 01 Small Label */
  font-family: Inter, serif;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.score-value {
  color: #545454;

  /* 06 Large Numbers */
  font-family: Inter, serif;
  font-size: 26px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.score-increase {
  color: var(--Dark-Green, #12794F);
}

.score-decrease {
  color: var(--Red, #E70000);
}

.score-percentage {


  /* 01 Small Label */
  font-family: Inter, serif;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
