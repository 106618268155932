.dialogTitle {
  font-family: "Inter";
  font-size: 12px;
  color: black;
}

.bid-table {
  tr {
    border-bottom: 0.25px solid #bab7b7;

    &:nth-last-child(1) {
      border-bottom: none;
    }

    &:first-child {
      border-bottom: 1.5px solid #545454;
    }

    th {
      text-align: start;
      color: black;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 10px 15px;
    }

    td {
      padding: 10px 15px;
      color: black;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .bidHistoryMessage {
    margin-top: 12px;
    font-size: 14px;
    color: Grey;
    text-align: center;
  }
}

.arrow-popover {
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid white;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
