.alert-center{

    .alert-center-header-container{
        display: flex;
        flex-direction: column;
    }

    .new-alert-button{
        display: flex;
        align-self: end;
        padding-right: 16px;
    }

}